import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-tooltip/dist/react-tooltip.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './redux/state/store';

import './index.scss';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container!);

// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </React.StrictMode>,
// );

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
