import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { NativeSelect } from '@mui/material';

export interface IOption {
  value: string;
  label: string;
}

export default function MultiSelectDropdowComponent(props: {
  options: IOption[];
  label?: string;
  onChange: Function;
  value: IOption;
  section?: 'main' | 'forecast' | 'seasonality';
}) {
  return (
    <FormControl fullWidth>
      <InputLabel variant="standard">{props.label}</InputLabel>
      <NativeSelect onChange={event => props.onChange(event, props.section)} value={props.value?.value || ''}>
        {props.options.map((option, index) => (
          <option key={`${option.value}${index}`} value={option.value}>
            {option.label}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
}
