import axios from 'axios';
import { WithAbortFn } from '../../@types/external-api';
import { apiWrapper } from '../../config/api-wrapper';
import { ReviewSheetTemplate, LegalEntity } from './review-sheet-types';

class ReviewSheetService {
  public getTemplates(): WithAbortFn<Promise<ReviewSheetTemplate[]>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api.get('/review-sheet/templates').then((res: any) => res.data),
      abort: source.cancel,
    };
  }

  public newReviewSheet(
    recordId: string,
    reviewSheetTemplateId: number,
    content: string,
    apContactName: string,
    number: string,
    title: string,
    signature: string,
    email: string,
    emailCc: string,
  ): WithAbortFn<Promise<any>> {
    const source = axios.CancelToken.source();
    const data = {
      recordId,
      reviewSheetTemplateId,
      content,
      apContactName,
      number,
      title,
      signature,
      email,
      emailCc,
    };
    return {
      promise: apiWrapper.api.put(`/review-sheet/new-review-sheet`, data).then((res: any) => res.data),
      abort: source.cancel,
    };
  }

  public getLegalEntities(): WithAbortFn<Promise<LegalEntity[]>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api.get('/review-sheet/legal-entities').then((res: any) => res.data),
      abort: source.cancel,
    };
  }
}

const reviewSheetService = new ReviewSheetService();
export default reviewSheetService;
