import { LogoLoading } from '@amway/react-components';
import { TableBody, TableCell, TableRow } from '@mui/material';
import './index.scss';

interface Props {
  colsNumber: number;
}

const TableLoadingComponent: React.FC<Props> = ({ colsNumber }) => {
  const middleCol = Math.floor(colsNumber / 2);

  return (
    <TableBody className="table-loading-component-border">
      {Array.from(Array(5).keys()).map(i => (
        <TableRow key={i}>
          <TableCell className="table-loading-component-border" />
        </TableRow>
      ))}
      <TableRow>
        {middleCol > 0 &&
          Array.from(Array(middleCol).keys()).map(i => (
            <TableCell key={i} className="table-loading-component-border" />
          ))}
        <TableCell className="table-loading-component-border">
          <LogoLoading />
        </TableCell>
      </TableRow>
      {Array.from(Array(5).keys()).map(i => (
        <TableRow key={i}>
          <TableCell className="table-loading-component-border" />
        </TableRow>
      ))}
    </TableBody>
  );
};

export default TableLoadingComponent;
