import { createContext } from 'react';

export interface ContentType {
  id: string;
}

export interface ContentCtxType {
  content: any;
  setContent: any;
}

export const ContentContext = createContext<ContentCtxType>(null as any);
