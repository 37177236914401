import { LogoLoading } from '@amway/react-components';
import './index.scss';
import React from 'react';

interface LoadingScreenProps {
  styles?: React.CSSProperties;
}

export default function LoadingScreen({ styles }: LoadingScreenProps) {
  return (
    <main className="loading-screen" style={styles}>
      <LogoLoading />
    </main>
  );
}
