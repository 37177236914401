import { useCallback, useState } from 'react';
import PipelineOutputContext from './pipeline-output-context';
import {
  IAffiliate,
  IAssignedTo,
  IPipelineOutput,
  IPipelineOutputDetails,
  IPipelineOutputRequest,
  IStatus,
  ISubmitter,
} from './pipeline-output-types';
import { ExternalData } from '../../@types/external-api';
import {
  makeExternalCallErrorData,
  makeExternalDataInitialData,
  makeExternalDataSuccessData,
} from '../../helpers/external-data';
import pipelineOutputService from './pipeline-output.service';

interface PipelineOutputProviderProps {
  children: React.ReactNode;
}

const PipelineOutputProvider: React.FC<PipelineOutputProviderProps> = ({ children }) => {
  const [pipelineOutputResults, setPipelineOutputResults] = useState<ExternalData<IPipelineOutput[]>>(
    makeExternalDataInitialData(),
  );
  const [pipelineOutputDetails, setPipelineOutputDetails] = useState<ExternalData<IPipelineOutputDetails>>(
    makeExternalDataInitialData(),
  );
  const [allAffiliates, setAllAffiliates] = useState<ExternalData<IAffiliate[]>>(makeExternalDataInitialData());
  const [allSubmitters, setAllSubmitters] = useState<ExternalData<ISubmitter[]>>(makeExternalDataInitialData());
  const [allStatus, setAllStatus] = useState<ExternalData<IStatus[]>>(makeExternalDataInitialData());
  const [allAssignedTo, setAllAssignedTo] = useState<ExternalData<IAssignedTo[]>>(makeExternalDataInitialData());

  const fetchPipelineOutputResults = useCallback((request: IPipelineOutputRequest) => {
    setPipelineOutputResults(makeExternalDataInitialData());
    try {
      const { promise, abort } = pipelineOutputService.getAllPipelineOutputs(request);
      promise.then(data => setPipelineOutputResults(makeExternalDataSuccessData(data)));
      return abort;
    } catch (err: any) {
      setPipelineOutputResults(makeExternalCallErrorData(err));
    }
  }, []);

  const fetchAllAffiliates = useCallback(() => {
    setAllAffiliates(makeExternalDataInitialData());
    try {
      const { promise, abort } = pipelineOutputService.getAllAffiliates();
      promise.then(data => setAllAffiliates(makeExternalDataSuccessData(data)));
      return abort;
    } catch (err: any) {
      setAllAffiliates(makeExternalCallErrorData(err));
    }
  }, []);

  const fetchAllStatus = useCallback(() => {
    setAllStatus(makeExternalDataInitialData());
    try {
      const { promise, abort } = pipelineOutputService.getAllStatus();
      promise.then(data => setAllStatus(makeExternalDataSuccessData(data)));
      return abort;
    } catch (err: any) {
      setAllStatus(makeExternalCallErrorData(err));
    }
  }, []);

  const fetchAllSubmitters = useCallback(() => {
    setAllSubmitters(makeExternalDataInitialData());
    try {
      const { promise, abort } = pipelineOutputService.getAllSubmitters();
      promise.then(data => setAllSubmitters(makeExternalDataSuccessData(data)));
      return abort;
    } catch (err: any) {
      setAllSubmitters(makeExternalCallErrorData(err));
    }
  }, []);

  const fetchAllAssignedTo = useCallback(() => {
    setAllAssignedTo(makeExternalDataInitialData());
    try {
      const { promise, abort } = pipelineOutputService.getAllAssignedsAvailable();
      promise.then(data => setAllAssignedTo(makeExternalDataSuccessData(data)));
      return abort;
    } catch (err: any) {
      setAllAssignedTo(makeExternalCallErrorData(err));
    }
  }, []);

  const fetchPipelineOutputDetails = useCallback((id: string) => {
    setPipelineOutputDetails(makeExternalDataInitialData());
    try {
      const { promise, abort } = pipelineOutputService.getPipelineOutputDetails(id);
      promise.then(data => setPipelineOutputDetails(makeExternalDataSuccessData(data)));
      return abort;
    } catch (err: any) {
      setPipelineOutputDetails(makeExternalCallErrorData(err));
    }
  }, []);

  return (
    <PipelineOutputContext.Provider
      value={{
        pipelineOutputResults,
        allAffiliates,
        allSubmitters,
        allStatus,
        allAssignedTo,
        pipelineOutputDetails,
        fetchAllAssignedTo,
        fetchPipelineOutputResults,
        fetchAllAffiliates,
        fetchAllSubmitters,
        fetchAllStatus,
        fetchPipelineOutputDetails,
      }}>
      {children}
    </PipelineOutputContext.Provider>
  );
};

export default PipelineOutputProvider;
