import { Accordion } from 'react-bootstrap';
import { Paper } from '@mui/material';
import { useAppSelector } from '../../../redux/hooks';
import { Review } from '../../../interface/review';
import parse from 'html-react-parser';

import './index.scss';
import { formatDate } from '../../../helpers/reviews-helper';
import moment from 'moment';

export default function ReviewHistoryTab() {
  const { reviews } = useAppSelector(state => state.submission);
  const filteredReviews = reviews.filter((review: Review) => review.reviewSheet) as Review[];
  const sortedReviews = filteredReviews.sort(sortByReviewSheetCreationDate) as Review[];
  const firstReview = sortedReviews[0];

  function sortByReviewSheetCreationDate(a: Review, b: Review) {
    if (moment(a.reviewSheet.createdAt).isBefore(b.reviewSheet.createdAt)) {
      return 1;
    } else if (moment(a.reviewSheet.createdAt).isAfter(b.reviewSheet.createdAt)) {
      return -1;
    }
    return 0;
  }

  return (
    <Paper className="review-history-tab" variant="outlined">
      <div className="paper-title">
        <span className="paperText">Review Sheet History</span>
      </div>
      <Accordion defaultActiveKey={`${firstReview.id}`} alwaysOpen>
        {sortedReviews.map((review: Review) => (
          <Accordion.Item eventKey={`${review.id}`} key={review.id}>
            <Accordion.Header>
              <strong>{`${formatDate(review.reviewSheet.createdAt)}`}</strong>&nbsp; - &nbsp;
              {`${review.reviewSheet.title}`}
            </Accordion.Header>
            <Accordion.Body>{parse(review.reviewSheet.content)}</Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </Paper>
  );
}
