import './index.scss';
import { Paper } from '@mui/material';
import { formatSourceDate } from '../../dashboard/utils';
import { marked } from 'marked';
import { useEffect } from 'react';
import { SummaryDetail } from '../../../interface/summary-detail';
import { useAppSelector } from '../../../redux/hooks';

export default function SummaryTab() {
  const { currentRecord } = useAppSelector(state => state.submission);
  const creationDate = formatSourceDate(currentRecord?.sourceDate);

  const submissionDetails: Array<SummaryDetail> = [
    {
      title: 'Apply Code:',
      value: currentRecord?.applyCode || '',
    },
    {
      title: 'Upload Date:',
      value: creationDate,
    },
    {
      title: 'Submitted by:',
      value: currentRecord?.submitterName || '',
    },
    {
      title: 'Assined to:',
      value: currentRecord?.assignedToName || '',
    },
    {
      title: 'Status:',
      value: currentRecord?.formStatus || '',
    },
    {
      title: 'Analyst Decision:',
      value: currentRecord?.analystDecision || '',
    },
  ];

  function renderMarkdown(markdownText: string): string {
    // Use marked library to convert Markdown to HTML
    return marked.parse(markdownText).toString();
  }

  useEffect(() => {
    const summaryTextEl = document.getElementById('summary-text');
    const processedSummary = removeSectionByTitle(
      currentRecord?.transcriptSummary || '',
      'Claims that may have compliance issues',
    );

    if (summaryTextEl) {
      try {
        summaryTextEl.innerHTML = renderMarkdown(processedSummary);
      } catch (error) {
        summaryTextEl.innerHTML = processedSummary;
      }
    }
  }, [currentRecord?.transcriptSummary]);

  function removeSectionByTitle(markdownText: string, sectionTitle: string): string {
    const sections = markdownText.split(/## /);
    const sectionIndex = sections.findIndex(section => section.startsWith(sectionTitle));

    if (sectionIndex !== -1) {
      sections.splice(sectionIndex, 1);
      const updatedMarkdown = sections.join('\n## ');

      return updatedMarkdown.trim() + '\n';
    }

    return markdownText;
  }

  return (
    <div className="summaryTab contentCards">
      <div className="paperSubmission">
        <Paper className="contentCard" variant="outlined">
          <div className="paper-title">
            <span className="paperText">Submission Details</span>
          </div>
          {currentRecord && (
            <div className="paperBody">
              {submissionDetails.map((submissionDetail: SummaryDetail, index) => (
                <p className="submissionDetails" key={index}>
                  <span className="detailsTitle">{submissionDetail.title}</span>
                  {submissionDetail.value}
                </p>
              ))}
            </div>
          )}
        </Paper>
      </div>

      <Paper className="contentCard paperSummary" variant="outlined">
        <div className="paper-title">
          <span className="paperText">Summary</span>
        </div>
        {currentRecord && (
          <div id="summary-text" className="dataParagraph submissionSummary paperBody">
            {currentRecord.transcriptSummary}
          </div>
        )}
      </Paper>
    </div>
  );
}
