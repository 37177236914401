import { AuthState, OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiWrapper } from '../../config/api-wrapper';
import oktaAuth from '../../config/okta-auth';
import AuthContext from './auth-context';
import { SingInOptions } from './auth-types';
import authService from './auth.service';
import { User } from './user-model';
import { ExternalData } from '../../@types/external-api';
import {
  makeExternalCallErrorData,
  makeExternalDataInitialData,
  makeExternalDataSuccessData,
} from '../../helpers/external-data';

export default function AuthProvider({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | undefined>();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [allUsers, setAllUsers] = useState<ExternalData<User[]>>(makeExternalDataInitialData());

  const signIn = useCallback(async (options: SingInOptions): Promise<void> => {
    void oktaAuth.signInWithRedirect(options);
  }, []);

  const signOut = useCallback((): void => {
    void oktaAuth.signOut();
  }, []);

  const onOktaAuthChanges = useCallback(async (authState: AuthState) => {
    try {
      if (!authState.isAuthenticated) throw new Error('Not authenticated');

      apiWrapper.setOktaThing(
        authState.accessToken!.accessToken,
        authState.idToken!.idToken,
        authState.idToken!.claims.nonce,
      );
      setIsAuthenticated(true);
      const user = (await oktaAuth.token.getUserInfo()) as any;
      //console.info("user", user);
      // /console.info("groupsPrefix", groupsPrefix);
      //const userGroup = (user[groupsPrefix][0] as string) ?? '';
      const { nativeid, fullName, email } = user;
      setUser({ id: nativeid, name: fullName as string, email: email as string });
    } catch (err) {
      console.info('onOktaAuthChanges err', err);
      setIsAuthenticated(false);
      setUser(undefined);
      apiWrapper.clearOktaThing();
    }
  }, []);

  const handleRestoreOriginalUri = useCallback(
    (_oktaAuth: OktaAuth, originalUri: string) => {
      navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true });
    },
    [navigate],
  );
  useEffect(() => {
    oktaAuth.authStateManager.subscribe((authState: AuthState) => {
      onOktaAuthChanges(authState);
      authService.oktaAuthState = authState;
    });

    oktaAuth.authStateManager.updateAuthState().then(() => {
      oktaAuth.tokenManager.getTokens().then(({ accessToken, idToken }) => {});
    });

    return () => {
      oktaAuth.authStateManager.unsubscribe();
    };
  }, [onOktaAuthChanges]);

  const fetchAllUsers = useCallback(async () => {
    try {
      const users = await authService.getUsers('');
      setAllUsers(makeExternalDataSuccessData(users));
    } catch (err) {
      setAllUsers(makeExternalCallErrorData(err as any));
      console.error(err);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, isAuthenticated, allUsers, signIn, signOut, fetchAllUsers }}>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={handleRestoreOriginalUri}>
        {children}
      </Security>
    </AuthContext.Provider>
  );
}
