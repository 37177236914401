import { Modal } from 'react-bootstrap';
import './index.scss';
import { RuleViolation } from '../../../../interface/issue-occurrence';
import RuleViolationIcon from '../../../../img/RuleViolationIcon';
import { useAppSelector, useAppDispatch } from '../../../../redux/hooks';
import { ReviewIssueViolationRule } from '../../../../resources/submission/submission-types';
import * as utils from '../../utils';
import parse from 'html-react-parser';
import LoaderComponent from '../../../../components/ui/loader';
import submissionService from '../../../../resources/submission/submission.service';
import { useState } from 'react';

interface IssuesModalProps {
  showModal: boolean;
  closeModal: Function;
  ruleViolations: Array<RuleViolation>;
  confirmButton: Function;
  isLoading: boolean;
}

export default function IssuesModal({
  showModal,
  closeModal,
  ruleViolations,
  confirmButton,
  isLoading,
}: IssuesModalProps) {
  const { violationRules } = useAppSelector(state => state.transcript);
  const [isLocalLoading, setIsLocalLoading] = useState<boolean>(false);

  function deleteSubmissionReviews() {
    setIsLocalLoading(true);
    submissionService
      .deleteSubmissionIssues(ruleViolations)
      .promise.then(res => {
        setIsLocalLoading(false);
        confirmButton();
      })
      .catch(error => {
        console.error(error);
      });
  }

  return (
    <Modal size="lg" className="issue-modal-container" centered show={showModal} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title className="issue-modal-title">
          The following Rules Violation no longer exists on the new transcript version and will be removed. Are you sure
          you want to proceed?
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="issue-modal-rule-violation-cards">
          {ruleViolations.map((ruleViolation: RuleViolation) => (
            <div className="issue-modal-rule-violation-card" key={ruleViolation.reviewIssue.id}>
              {ruleViolation.reviewIssueViolationRule.map((issue: ReviewIssueViolationRule) => (
                <div className="violation-rule" key={`${ruleViolation.reviewIssue.id}_${issue.id}`}>
                  <RuleViolationIcon fill="red" />
                  <span>
                    <strong>{violationRules?.find((vr: any) => vr.id === issue['violationRuleId'])?.name}</strong>
                  </span>
                </div>
              ))}
              {ruleViolation.reviewIssue.issueContext ? (
                <p>{parse(utils.processHighlightedIssues(ruleViolation))}</p>
              ) : (
                <p>{parse(utils.highlightWithYellowBackground(ruleViolation.reviewIssue.issueContent))}</p>
              )}
            </div>
          ))}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button className="issue-modal-button cancel" onClick={() => closeModal()}>
          Cancel
        </button>
        <button className="issue-modal-button confirm" onClick={() => deleteSubmissionReviews()}>
          {isLoading || isLocalLoading ? (
            <LoaderComponent styles={{ border: '0.2em solid white', borderTop: '0.2em solid transparent' }} />
          ) : (
            'Confirm'
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
