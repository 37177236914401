export function capitalizeFirstLetter(string: any) {
  if (typeof string !== 'string' || string.length === 0) {
    // Handle invalid input, return an empty string, or throw an error as needed.
    return string; // Or return an empty string: return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function escapeRegExp(text: string) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}
