import * as React from 'react';
import './index.scss';

const typeOptions = ['info', 'alert'];

export default function ViolationTag(props: { label: string; type?: string }) {
  const label = props.label === 'ML' ? 'AI' : props.label;
  const type = !typeOptions.includes(props.type || '') ? 'info' : props.type;

  return (
    <div className={`violation-tag-container violation-tag-${type}`}>
      <span>{label}</span>
    </div>
  );
}
