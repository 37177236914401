import { IOption } from '../components/ui/multi-select-dropdown';

interface IFiltersLocalStorage {
  search: string;
  affiliate: IOption;
  status: IOption;
  selectedSubmitter: string;
  selectedUser: string;
}

const defaultFilters: IFiltersLocalStorage = {
  search: '',
  affiliate: { value: '', label: '' },
  status: { value: '', label: '' },
  selectedSubmitter: '',
  selectedUser: '',
};

export const saveFiltersToStorage = (filters: IFiltersLocalStorage) => {
  localStorage.setItem('saved-filters', JSON.stringify(filters));
};

export const getFiltersFromStorage = (): IFiltersLocalStorage => {
  const savedFilters = localStorage.getItem('saved-filters');

  if (savedFilters) return JSON.parse(savedFilters);

  saveFiltersToStorage(defaultFilters);
  return defaultFilters;
};
