import { CiEdit } from 'react-icons/ci';
import { MdThumbUp, MdThumbDown } from 'react-icons/md';
import { Tooltip } from 'react-tooltip';

import ViolationTag from '../../../../components/ui/violation-tag';
import RuleViolationIcon from '../../../../img/RuleViolationIcon';
import { formatVideoTime } from '../../../../utils/video-utils';
import { selectTranscriptSection } from '../../utils';
import { IssueOccurrence, RuleViolation } from '../../../../interface/issue-occurrence';
import AlertMessages from '../../../../resources/messages/alerts.json';
import LoaderComponent from '../../../../components/ui/loader';

import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import * as submissionReducer from '../../../../redux/reducers/submissionReducer';
import * as transcriptReducer from '../../../../redux/reducers/transcriptReducer';
import * as ruleViolationModalReducer from '../../../../redux/reducers/ruleViolationModalReducer';

import './index.scss';
import { ReviewIssueViolationRule } from '../../../../resources/submission/submission-types';
import { getLastReview, hasTheReviewBeenSent } from '../../../../helpers/reviews-helper';
import { Review } from '../../../../interface/review';
import submissionService from '../../../../resources/submission/submission.service';
import { useEffect, useState } from 'react';
import {
  SubmissionIssueDTO,
  UpdateUserReviewStatusResponseDTO,
} from '../../../../resources/pipeline-output/pipeline-output-types';

interface RuleViolationCardProps {
  occurrence: RuleViolation;
  isDisplayingRejected: boolean;
  index: number;
}

interface ThumbsRequesting {
  issueId: number;
  isRequesting: boolean;
}

export default function RuleViolationCard({ occurrence, isDisplayingRejected, index }: RuleViolationCardProps) {
  const [isThumbUpRequestingArray, setIsThumbUpRequestingArray] = useState<Array<ThumbsRequesting>>();
  const [isThumbDownRequestingArray, setIsThumbDownRequestingArray] = useState<Array<ThumbsRequesting>>();

  const dispatch = useAppDispatch();
  const { violationRules, isRvActionsRequesting, currentTab, filteredIssuesOccurrences, filteredIssuesRuleViolations } =
    useAppSelector(state => state.transcript);
  const { isTranscriptExtended, isSelectingContext, enableReview, blockedInteraction } = useAppSelector(
    state => state.submission,
  );
  const submissionReviews: SubmissionIssueDTO[] = useAppSelector(state => state.submission.submissionReviews);
  const selectedIssueOccurrence: RuleViolation = useAppSelector(state => state.transcript.selectedIssueOccurrence);
  const reviews: Review[] = useAppSelector(state => state.submission.reviews);
  const lastReview = getLastReview(reviews);

  const timeStart = occurrence.reviewIssue.timeStart;
  const ruleViolationId = `ruleViolation_${timeStart}_${index}`;
  const thumbUpClass = `thumb_up_${timeStart}_${index}`.replaceAll('.', '_');
  const thumbDownClass = `thumb_down_${timeStart}_${index}`.replaceAll('.', '_');
  const editClass = `edit_${timeStart}_${index}`.replaceAll('.', '_');

  const allWasRejected = occurrence.reviewIssueViolationRule.every(
    (issue: ReviewIssueViolationRule) => issue.userReviewStatus === false,
  );

  useEffect(() => {
    buildThumbsRequestingArray();
  }, []);

  useEffect(() => {
    if (isTranscriptExtended) {
      buildThumbsRequestingArray();
    }
  }, [selectedIssueOccurrence]);

  function occurrenceClick(timeStart: number) {
    selectTranscriptSection(timeStart);
    dispatch(transcriptReducer.setByPassSync(true));
  }

  function isSelected(): boolean {
    if (!selectedIssueOccurrence.reviewIssue) {
      return false;
    } else if (selectedIssueOccurrence.reviewIssue.id === occurrence.reviewIssue.id) {
      return true;
    }
    return false;
  }

  /**
   * Thumb action on a rule violation.
   * @param thumbType
   * @param clickedIssue
   */
  function thumbClick(thumbType: string, clickedIssue: ReviewIssueViolationRule) {
    clearInfoAlert();

    if (hasTheReviewBeenSent(lastReview) && !enableReview) {
      handleInfoMessage(AlertMessages.THE_REVIEW_HAS_ALREADY_BEEN_SENT);
      return;
    }

    if (blockedInteraction) {
      dispatch(submissionReducer.setTranscriptTabInfoMessage(AlertMessages.NOT_IN_LAST_TRANSCRIPT_VERSION));
      return;
    }

    if (
      isSelectingContext ||
      isRvActionsRequesting ||
      (thumbType === 'UP' && clickedIssue.userReviewStatus === true) ||
      (thumbType === 'DOWN' && clickedIssue.userReviewStatus === false)
    ) {
      return;
    }

    const userReviewStatus = thumbType === 'UP';

    try {
      dispatch(transcriptReducer.setIsRvActionsRequesting(true));
      updateThumbIsRequesting(thumbType, clickedIssue.id!, true);

      submissionService
        .updateUserReviewStatus(clickedIssue.id!, userReviewStatus)
        .promise.then((updateUserReviewStatusResponseDTO: UpdateUserReviewStatusResponseDTO) => {
          updateReviewIssueViolationRule(updateUserReviewStatusResponseDTO);
        })
        .catch((err: Error) => {
          if (err.message.includes('401')) {
            dispatch(transcriptReducer.setTranscriptTabErrorMessage(`${AlertMessages.HTTP_401}`));
          } else {
            dispatch(transcriptReducer.setTranscriptTabErrorMessage(`${AlertMessages.UNKNOWN_ERROR} ${err.message}`));
          }
        })
        .finally(() => {
          updateThumbIsRequesting(thumbType, clickedIssue.id!, false);
          dispatch(transcriptReducer.setIsRvActionsRequesting(false));
        });
    } catch (err: any) {
      if (err.message.includes('401')) {
        dispatch(transcriptReducer.setTranscriptTabErrorMessage(`${AlertMessages.HTTP_401}`));
      } else {
        dispatch(transcriptReducer.setTranscriptTabErrorMessage(`${AlertMessages.UNKNOWN_ERROR} ${err.message}`));
      }
      updateThumbIsRequesting(thumbType, clickedIssue.id!, false);
      dispatch(transcriptReducer.setIsRvActionsRequesting(false));
    }
  }

  /**
   * Show the modal with the current violations to allow the user to add/remove rule violations.
   * @param issueId
   */
  function editClick() {
    clearInfoAlert();

    if (isSelectingContext || isRvActionsRequesting) {
      return;
    }

    if (hasTheReviewBeenSent(lastReview) && !enableReview) {
      handleInfoMessage(AlertMessages.THE_REVIEW_HAS_ALREADY_BEEN_SENT);
      return;
    }

    if (blockedInteraction) {
      dispatch(submissionReducer.setTranscriptTabInfoMessage(AlertMessages.NOT_IN_LAST_TRANSCRIPT_VERSION));
      return;
    }

    dispatch(ruleViolationModalReducer.setCurrentIssueContent(occurrence.reviewIssue.issueContent));
    dispatch(ruleViolationModalReducer.setCurrentHighlightsArray(occurrence.reviewIssue.issues));
    dispatch(ruleViolationModalReducer.setOriginalHighlightsArray(occurrence.reviewIssue.issues));
    dispatch(ruleViolationModalReducer.setCurrentContext(occurrence.reviewIssue.issueContext));
    dispatch(ruleViolationModalReducer.setOriginalContext(occurrence.reviewIssue.issueContext));
    dispatch(ruleViolationModalReducer.setReviewIssueId(occurrence.reviewIssue.id));
    dispatch(ruleViolationModalReducer.setReviewIssueViolationRules(occurrence.reviewIssueViolationRule));
    dispatch(ruleViolationModalReducer.setTimeStart(occurrence.reviewIssue.timeStart));
    dispatch(ruleViolationModalReducer.setTimeEnd(occurrence.reviewIssue.timeEnd));
    dispatch(ruleViolationModalReducer.setMode('issue'));
    dispatch(ruleViolationModalReducer.setShowModal(true));
  }

  function handleInfoMessage(message: string) {
    dispatch(submissionReducer.setTranscriptTabInfoMessage(message));
  }

  function clearInfoAlert() {
    dispatch(submissionReducer.setTranscriptTabInfoMessage(''));
  }

  /**
   * Updates a single review violation rule and it's review issue review status.
   */
  function updateReviewIssueViolationRule(updateUserReviewStatusResponseDTO: UpdateUserReviewStatusResponseDTO) {
    let updatedSubmissionReviews = submissionReviews.map((submissionReview: SubmissionIssueDTO) => {
      // Check if this review contains the review issue that needs to be updated.
      if (
        submissionReview.reviewIssue.id === updateUserReviewStatusResponseDTO.reviewIssueViolationRule.reviewIssueId
      ) {
        // Update the review issue user review status.
        const updatedReviewIssue = {
          ...submissionReview.reviewIssue,
          userReviewStatus: updateUserReviewStatusResponseDTO.reviewIssueUserReviewStatus,
        };

        // Update the review issue violation rules.
        const updatedReviewIssueViolationRules = submissionReview.reviewIssueViolationRule.map(rivr => {
          if (rivr.id === updateUserReviewStatusResponseDTO.reviewIssueViolationRule.id) {
            return {
              ...rivr,
              userReviewStatus: updateUserReviewStatusResponseDTO.reviewIssueViolationRule.userReviewStatus,
            };
          }
          return rivr;
        });

        return {
          ...submissionReview,
          reviewIssue: updatedReviewIssue,
          reviewIssueViolationRule: updatedReviewIssueViolationRules,
        };
      }

      return submissionReview;
    });

    dispatch(submissionReducer.setSubmissionReviews(updatedSubmissionReviews));
    updateSelectedIssueOccurrence(updatedSubmissionReviews, updateUserReviewStatusResponseDTO.reviewIssueViolationRule);
  }

  function buildThumbsRequestingArray() {
    const isRequestingArray = Array<ThumbsRequesting>();

    occurrence.reviewIssueViolationRule.forEach((rivr: ReviewIssueViolationRule) => {
      isRequestingArray.push({ issueId: rivr.id!, isRequesting: false });
    });

    setIsThumbUpRequestingArray(isRequestingArray);
    setIsThumbDownRequestingArray(isRequestingArray);
  }

  function updateThumbIsRequesting(thumbType: string, issueId: number, isRequesting: boolean) {
    if (thumbType === 'UP') {
      const updatedIsThumbUpRequestingArray = isThumbRequestingMap(
        isThumbUpRequestingArray || [],
        issueId,
        isRequesting,
      );

      setIsThumbUpRequestingArray(updatedIsThumbUpRequestingArray);
    } else {
      const updatedIsThumbDownRequestingArray = isThumbRequestingMap(
        isThumbDownRequestingArray || [],
        issueId,
        isRequesting,
      );

      setIsThumbDownRequestingArray(updatedIsThumbDownRequestingArray);
    }
  }

  function isThumbRequestingMap(
    thumbsRequestingArray: Array<ThumbsRequesting>,
    issueId: number,
    isRequesting: boolean,
  ) {
    return thumbsRequestingArray?.map((item: ThumbsRequesting) => ({
      ...item,
      isRequesting: issueId === item.issueId ? isRequesting : item.isRequesting,
    }));
  }

  function thumbIsRequesting(thumbType: string, issueId: number): boolean {
    if (thumbType === 'UP') {
      return isThumbUpRequestingArray?.find((item: ThumbsRequesting) => issueId === item.issueId)
        ?.isRequesting as boolean;
    }
    return isThumbDownRequestingArray?.find((item: ThumbsRequesting) => issueId === item.issueId)
      ?.isRequesting as boolean;
  }

  function updateSelectedIssueOccurrence(
    submissionReviews: SubmissionIssueDTO[],
    reviewIssueViolationRule: ReviewIssueViolationRule,
  ) {
    const submissionReview = submissionReviews.find(
      (submissionReview: SubmissionIssueDTO) =>
        submissionReview.reviewIssue.id === reviewIssueViolationRule.reviewIssueId,
    );

    const isAllFalse = submissionReview?.reviewIssueViolationRule.every(
      (reviewIssueViolationRule: ReviewIssueViolationRule) => reviewIssueViolationRule.userReviewStatus === false,
    );

    if (isAllFalse && !isDisplayingRejected) {
      let foundIssueOccurrence = {} as IssueOccurrence;

      if (currentTab === 'All') {
        foundIssueOccurrence = filteredIssuesOccurrences.find((issueOccurrence: any) => {
          if (
            !issueOccurrence.occurrence.reviewIssue &&
            issueOccurrence.startTime > submissionReview?.reviewIssue.timeStart!
          ) {
            return true;
          }
          const occurrence = issueOccurrence.occurrence as RuleViolation;

          return (
            issueOccurrence.startTime > submissionReview?.reviewIssue.timeStart! &&
            occurrence.reviewIssue.userReviewStatus !== false
          );
        });
      } else if (currentTab === 'Rule Violation') {
        foundIssueOccurrence = filteredIssuesRuleViolations.find((issueOccurrence: any) => {
          const occurrence = issueOccurrence.occurrence as RuleViolation;

          return (
            issueOccurrence.startTime > submissionReview?.reviewIssue.timeStart! &&
            occurrence.reviewIssue.userReviewStatus !== false
          );
        });
      }

      if (foundIssueOccurrence) {
        if (foundIssueOccurrence.startTime) {
          selectTranscriptSection(foundIssueOccurrence.startTime);
        } else {
          dispatch(transcriptReducer.setSelectedIssueOccurrence(foundIssueOccurrence));
        }
      }
    } else {
      dispatch(transcriptReducer.setSelectedIssueOccurrence(submissionReview));
    }
  }

  return (
    <div
      key={ruleViolationId}
      id={ruleViolationId}
      className={`rule-violation-card-container ${isSelected() ? 'selected' : ''}`}
      hidden={allWasRejected && !isDisplayingRejected}
      onClick={() => occurrenceClick(timeStart!)}>
      <div hidden={isTranscriptExtended} className="time-line"></div>
      <p className="time">
        <strong>{formatVideoTime(timeStart!)}</strong>
      </p>
      <div className="ruleViolationContainer">
        {occurrence?.reviewIssueViolationRule.map((issue: ReviewIssueViolationRule, issueIndex: number) => (
          <div
            className="ruleViolationDiv"
            hidden={issue.userReviewStatus === false && !isDisplayingRejected}
            key={`${ruleViolationId}_${issueIndex}`}>
            <div className="issueCardContainer">
              <div className="card-tag-container">
                <div className="issueCard ruleViolationCard">
                  <RuleViolationIcon fill="red" />
                  <p>
                    <strong>{violationRules?.find((vr: any) => vr.id === issue['violationRuleId'])?.name}</strong>
                  </p>
                </div>
                <div className="violation-tags-container">
                  {occurrence?.reviewIssue.issueOrigin === 'ML' && issueIndex === 0 ? (
                    <div
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Rule violation found by artificial intelligence">
                      <ViolationTag label="AI" type="info" />
                    </div>
                  ) : (
                    <></>
                  )}
                  {occurrence?.reviewIssue.issueContext === null ? (
                    <div data-tooltip-id="my-tooltip" data-tooltip-content="Rule violation missing context text">
                      <ViolationTag label="MC" type="alert" />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <p className="issueExplanation" hidden={!isTranscriptExtended}>
                {issue['description']?.replaceAll('double_quotes', '"')}
              </p>
              <div className="actionsCard">
                <div
                  className={`thumbUp ${thumbUpClass}-${issueIndex} ${
                    issue.userReviewStatus === true ? 'thumb-up-selected' : ''
                  }`}
                  onClick={() => thumbClick('UP', issue)}>
                  {thumbIsRequesting('UP', issue.id!) ? (
                    <LoaderComponent />
                  ) : (
                    <MdThumbUp data-tooltip-id="my-tooltip" data-tooltip-content="I agree this is a rule violation" />
                  )}
                </div>
                <div
                  className={`thumbDown ${thumbDownClass}-${issueIndex} ${
                    issue.userReviewStatus === false ? 'thumb-down-selected' : ''
                  }`}
                  onClick={() => thumbClick('DOWN', issue)}>
                  {thumbIsRequesting('DOWN', issue.id!) ? (
                    <LoaderComponent />
                  ) : (
                    <MdThumbDown
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="I disagree this is a rule violation"
                    />
                  )}
                </div>
                <div className={`edit ${editClass}`} onClick={() => editClick()}>
                  <CiEdit />
                </div>
              </div>
            </div>
            <p className="issueExplanation" hidden={isTranscriptExtended}>
              {issue['description']?.replaceAll('double_quotes', '"')}
            </p>
          </div>
        ))}
      </div>

      <Tooltip id="my-tooltip" place="top" />
    </div>
  );
}
