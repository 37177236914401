import { capitalizeFirstLetter } from '../../../../utils/string-utils';
import KeywordIcon from '../../../../img/KeywordIcon';
import { formatVideoTime } from '../../../../utils/video-utils';

import { selectTranscriptSection } from '../../utils';
import './index.scss';
import { Keyword } from '../../../../interface/issue-occurrence';
import * as transcriptReducer from '../../../../redux/reducers/transcriptReducer';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

interface KeywordCardProps {
  occurrence: Keyword;
}

export default function KeywordCard({ occurrence }: KeywordCardProps) {
  const dispatch = useAppDispatch();
  const { isTranscriptExtended } = useAppSelector(state => state.transcript);
  const selectedIssueOccurrence: Keyword = useAppSelector(state => state.transcript.selectedIssueOccurrence);

  function occurenceClick(timeStart: number, ruleViolationId: string) {
    selectTranscriptSection(timeStart);
    dispatch(transcriptReducer.setSelectedIssueOccurrence(occurrence));
    dispatch(transcriptReducer.setByPassSync(true));
  }

  function isSelected(): boolean {
    if (!selectedIssueOccurrence.keywordId) {
      return false;
    } else if (selectedIssueOccurrence.keywordId === occurrence.keywordId) {
      return true;
    }
    return false;
  }

  return (
    <div
      key={occurrence.keywordId}
      id={occurrence.keywordId}
      className={`keyword-card-container ${isSelected() ? 'selected' : ''}`}
      onClick={() => {
        occurenceClick(occurrence.startTime, occurrence.keywordId);
      }}>
      <div hidden={isTranscriptExtended} className="time-line"></div>
      <p className="time">
        <strong>{formatVideoTime(occurrence.startTime)}</strong>
      </p>
      <div className="issueCard keyCard">
        <KeywordIcon fill="black" />
        <p>
          <strong>{capitalizeFirstLetter(occurrence.keyword)}</strong>
        </p>
      </div>
    </div>
  );
}
