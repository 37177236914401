import React, { useCallback, useState } from 'react';
import { Outlet } from 'react-router-dom';
import NavBarComponent from '../navbar';
import './index.scss';

const AuthenticatedLayout: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  const toggleMenu = useCallback(() => {
    setOpen(!open);
  }, [setOpen, open]);

  return (
    <>
      <NavBarComponent />
      <div className="aside-main">
        {/* <SideNavComponent open={open} toggleMenu={toggleMenu} onLogout={signOut} /> */}
        <main id="main" onClick={open ? toggleMenu : undefined}>
          <Outlet />
        </main>
      </div>
    </>
  );
};

export default AuthenticatedLayout;
