import { Header } from '@amway/react-components';
import { Environments } from '../../../@types/configuration';
import environment, { appTitle } from '../../../config/env';
import useAuth from '../../../resources/auth/auth-hook';

import './index.scss';

export default function Navbar() {
  const { isAuthenticated, user } = useAuth();

  const shouldShowQaContainer = [Environments.DEV, Environments.QA].includes(environment.ENV);

  return (
    <div className="header-container">
      <Header
        title={appTitle}
        isAuthenticated={isAuthenticated ?? false}
        shouldShowQaContainer={shouldShowQaContainer}
        environment={environment.ENV}
        user={user}
      />
    </div>
  );
}
