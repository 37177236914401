import './violation-rule-modal.scss';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Modal, Button, Dropdown } from 'react-bootstrap';
import { CiCircleMinus, CiCirclePlus } from 'react-icons/ci';
import { ViolationRule, DropdownState } from '../../../resources/violation-rule/violation-rule-types';
import { v4 as uuidv4 } from 'uuid';
import submissionService from '../../../resources/submission/submission.service';
import { ReviewIssue, ReviewIssueViolationRule, IssueHightlight } from '../../../resources/submission/submission-types';
import AlertComponent from '../../../components/ui/alert';
import parse from 'html-react-parser';
import LoaderComponent from '../../../components/ui/loader';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import * as submissionReducer from '../../../redux/reducers/submissionReducer';
import * as ruleViolationModalReducer from '../../../redux/reducers/ruleViolationModalReducer';
import * as transcriptReducer from '../../../redux/reducers/transcriptReducer';
import { SubmissionIssueDTO } from '../../../resources/pipeline-output/pipeline-output-types';
import * as utils from '../utils';
import AlertMessages from '../../../resources/messages/alerts.json';

interface Row {
  id: number | string;
}

export default function ViolationRuleModal() {
  const initialRows: Row[] = [{ id: 1 }];

  const [rows, setRows] = useState<Row[]>(initialRows); // Initial rows with one item
  const [fullContext, setFullContext] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isRequesting, setIsRequesting] = useState<boolean>(false);

  const highlightSpan = `<span className='description'>$1</span>`;

  const dispatch = useAppDispatch();
  const { currentRecord, submissionReviews } = useAppSelector(state => state.submission);
  const { violationRules, nestedViolationRules, mergedSegments, mergedTranscript } = useAppSelector(
    state => state.transcript,
  );
  const {
    currentHighlightsArray,
    currentContext,
    originalContext,
    currentIssueContent,
    showModal,
    mode,
    reviewIssueViolationRules,
    timeStart,
    timeEnd,
    reviewIssueId,
    originalHighlightsArray,
    suggestedContext,
  } = useAppSelector(state => state.ruleViolationModal);

  const initialDropdownState: DropdownState = {
    id: undefined,
    showDropdown: false,
    showRightPanel: false,
    outmostSelectedParentViolationRule: '',
    selectedViolationRuleValue: '',
    selectedParentViolationRule: '',
    rightPanelViolationRules: [],
    description: '',
    violationRuleId: null,
  };

  // State variables for each dropdown.
  const [dropdownStates, setDropdownStates] = useState<DropdownState[]>(rows.map(() => ({ ...initialDropdownState })));

  // Load the initial dropdown (RV) values on the modal.
  useEffect(() => {
    if (reviewIssueViolationRules?.length) {
      // Initialize rows.
      let initialRowsAcc: Row[] = [];

      reviewIssueViolationRules.forEach((reviewIssueViolationRule: ReviewIssueViolationRule, index: number) => {
        if (reviewIssueViolationRule.userReviewStatus !== false) {
          initialRowsAcc.push({ id: index + 1 });
        }
      });

      const filteredInitialRows = initialRowsAcc.filter((row: Row) => row.id);

      if (filteredInitialRows.length === 0) {
        setRows(initialRows);
      } else {
        setRows(filteredInitialRows);
      }

      // Initialize dropdownStates.
      let initialDropdownStates: Array<DropdownState> = reviewIssueViolationRules.map(
        (reviewIssueViolationRule: ReviewIssueViolationRule) => {
          if (reviewIssueViolationRule.userReviewStatus !== false) {
            const violationRule = violationRules.find(
              (vr: ViolationRule) => vr.id === reviewIssueViolationRule.violationRuleId,
            ) as ViolationRule;
            const parentViolationRule = violationRules.find(
              (vr: ViolationRule) => vr.id === violationRule.parentId,
            ) as ViolationRule;
            const outmostViolationRule = violationRules.find(
              (vr: ViolationRule) => vr.id === parentViolationRule.parentId,
            ) as ViolationRule;

            return {
              id: reviewIssueViolationRule.id,
              showDropdown: false,
              showRightPanel: false,
              outmostSelectedParentViolationRule: outmostViolationRule?.name || '',
              selectedViolationRuleValue:
                (outmostViolationRule ? outmostViolationRule.name + ' > ' : '') +
                (parentViolationRule ? parentViolationRule.name + ' > ' : '') +
                (violationRule ? violationRule.name : 'Select a new Rule Violation'),
              selectedParentViolationRule: '',
              rightPanelViolationRules: [],
              description: reviewIssueViolationRule?.description || '',
              violationRuleId: reviewIssueViolationRule.violationRuleId,
            } as DropdownState;
          } else {
            return {
              ...initialDropdownState,
            };
          }
        },
      );

      initialDropdownStates = clearDropdownStates(initialDropdownStates);

      if (filteredInitialRows.length === 0) {
        setDropdownStates([initialDropdownState]);
      } else {
        setDropdownStates(initialDropdownStates);
      }
    } else {
      setRows(initialRows);
      setDropdownStates([initialDropdownState]);
    }
  }, [reviewIssueViolationRules, showModal, violationRules]);

  // Load initial context and highlights.
  useEffect(() => {
    if (!showModal) return;

    // In transcript mode, we don't need to highlight anything.
    if (mode === 'transcript') {
      setFullContext(currentContext);
      dispatch(ruleViolationModalReducer.setCurrentHighlightsArray([]));
      return;
    }

    if (currentContext && currentContext !== '') {
      if (currentHighlightsArray && currentHighlightsArray.length) {
        const finalContext = replaceRvOnContextByIndex(currentContext, currentHighlightsArray, false);
        setFullContext(finalContext);
      } else if (!originalHighlightsArray || !originalHighlightsArray.length) {
        const finalContext = replaceRvOnContext(currentContext, currentIssueContent);
        setFullContext(finalContext);
      } else {
        setFullContext(currentContext);
      }
    } else {
      const context = extractSectionWithContext('');
      dispatch(ruleViolationModalReducer.setSuggestedContext(context.contextSlice));
      dispatch(ruleViolationModalReducer.setCurrentContext(context.finalContext));
      const finalContext = replaceRvOnContext(context.finalContext, currentIssueContent);
      setFullContext(finalContext);
    }
  }, [currentContext, showModal]);

  // Event listener for text selection on mouseup.
  useEffect(() => {
    document.addEventListener('mouseup', handleTextSelection);

    return () => {
      document.removeEventListener('mouseup', handleTextSelection);
    };
  }, [currentContext, fullContext, showModal]);

  function handleTextSelection(event: MouseEvent) {
    if (shouldVerifyMouseEvent(event)) {
      const selection = window.getSelection();

      if (selection && selection.toString()) {
        const { index, selectedText } = utils.getSelectionIndex(selection);
        const newIssue: IssueHightlight = {
          issueContent: selectedText,
          issueIndex: index,
        };

        let contextWithRV = replaceRvOnContextByIndex(currentContext, [newIssue], true);
        setFullContext(contextWithRV);

        return;
      }
    }
  }

  function shouldVerifyMouseEvent(event: MouseEvent): boolean {
    if (!showModal) {
      return false;
    }

    const target = event.target as HTMLButtonElement;

    if (!target.className || typeof target.className !== 'string') {
      return false;
    }

    const targetClicked = event.target as HTMLButtonElement;
    const classNames = targetClicked.className;
    const isValidTarget = (classNames &&
      typeof classNames === 'string' &&
      (classNames.includes('context') ||
        classNames.includes('rule-violation') ||
        classNames.includes('description'))) as boolean;

    return isValidTarget;
  }

  /**
   * Track whether the toggle is open or not to show the right-panel
   */
  const handleToggle = (index: number, isOpen: boolean) => {
    setDropdownStates(prevState =>
      prevState.map((state, i) => (i === index ? { ...state, showDropdown: isOpen, showRightPanel: isOpen } : state)),
    );
  };

  /**
   * When a violation rule is clicked, check if it has any children, if true then we should show them on the right-panel
   * @param rule
   * @param index
   * @param parentRuleId Store the parentRuleId when clicking on an item on the left panel
   */
  const violationRuleClick = (rule: ViolationRule, index: number, parentRuleName?: string) => {
    setDropdownStates(prevState =>
      prevState.map((state, i) => {
        if (i === index) {
          // If the current rule has children we should show them on the right panel.
          if (rule.children?.length) {
            return {
              ...state,
              outmostSelectedParentViolationRule: parentRuleName!,
              selectedParentViolationRule: rule.name,
              rightPanelViolationRules: rule.children,
            };
          } else {
            // Click on a rule that does not have any children, select it.
            let selectedViolationRuleValue = '';
            if (parentRuleName) {
              selectedViolationRuleValue = parentRuleName + ' > ' + rule.name;
            } else {
              selectedViolationRuleValue =
                state.outmostSelectedParentViolationRule +
                ' > ' +
                state.selectedParentViolationRule +
                ' > ' +
                rule.name;
            }
            return {
              ...state,
              showDropdown: false,
              showRightPanel: false,
              selectedViolationRuleValue: selectedViolationRuleValue,
              violationRuleId: rule.id,
            };
          }
        }
        return state;
      }),
    );
  };

  /**
   * Replaces a rule violation text in a context text adding it's class to highlight it
   * @param issueContext The context selected by the user
   * @param issueContent The violation text
   * @returns
   */
  function replaceRvOnContext(issueContext: string, issueContent: string) {
    const escapeRegExp = (text: string) => {
      return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    };

    if (issueContent === '') {
      return issueContext;
    }

    // Ensure selectedText is properly escaped before constructing the regex pattern
    const escapedSelectedText = escapeRegExp(issueContent);

    // Construct the regex pattern using the escaped selected text
    const regexPattern = new RegExp(`(${escapedSelectedText})`, 'gi');

    // Replace the selected text with a <span> element
    const finalContext = issueContext.replace(regexPattern, highlightSpan);

    // Find the index of the replacement
    const replacementIndex = finalContext.toLowerCase().indexOf(issueContent.toLowerCase());
    const newIssue = [{ issueContent: issueContent, issueIndex: replacementIndex - 30 }];

    dispatch(ruleViolationModalReducer.setCurrentHighlightsArray(newIssue));
    dispatch(ruleViolationModalReducer.setOriginalHighlightsArray(newIssue));

    return finalContext;
  }

  /**
   * Replaces a rule violation text in a context by index text adding it's class to highlight it.
   * @param issueContext The context selected by the user
   * @param issueContent The violation text
   * @returns
   */
  function replaceRvOnContextByIndex(
    issueContext: string,
    receivedHighlights: IssueHightlight[],
    newHighlight: boolean,
  ) {
    // Ensure the index is valid
    if (!receivedHighlights || (receivedHighlights && receivedHighlights.length <= 0)) {
      return issueContext;
    }

    let updatedReceivedHighlights = receivedHighlights;
    if (!newHighlight) {
      updatedReceivedHighlights = utils.updateHighlightsIndex(
        currentContext || suggestedContext,
        issueContext,
        receivedHighlights,
        originalHighlightsArray,
      );
    }

    let allHighlights = updatedReceivedHighlights;

    if (newHighlight) {
      allHighlights = (currentHighlightsArray || []).concat(updatedReceivedHighlights);
    }

    // Sort the issues by issueIndex in descending order.
    let sortedHighlights: any = allHighlights!.slice().sort((a, b) => b.issueIndex - a.issueIndex);
    sortedHighlights = sortedHighlights.filter(
      (issue: any) =>
        issue.issueContent !== '' && issueContext.toLowerCase().includes(issue.issueContent.toLowerCase()),
    );
    sortedHighlights = utils.removeOverlapping(sortedHighlights);

    dispatch(ruleViolationModalReducer.setCurrentHighlightsArray(sortedHighlights));

    // Replace highlights on context.
    let finalContext = issueContext;
    sortedHighlights.forEach((highlight: any) => {
      if (!finalContext.toLowerCase().includes(highlight.issueContent.toLowerCase())) return;
      const endIndex = highlight.issueIndex + highlight.issueContent.length;
      const beforeText = finalContext.slice(0, highlight.issueIndex);
      let highlightText = finalContext.slice(highlight.issueIndex, endIndex);
      let afterText = finalContext.slice(endIndex);
      if (highlightText === '') {
        return;
      }
      highlightText = `<span class='description'>${highlightText}</span>`;
      finalContext = beforeText + highlightText + afterText;
    });

    if (window.getSelection) {
      window.getSelection()!.removeAllRanges();
    }

    return finalContext;
  }

  function removeWithNoIndex(issues: IssueHightlight[]) {
    if (typeof issues !== 'object') return issues;
    const issuesWithIndex = issues.filter(issue => issue.issueIndex !== -1 && issue.issueContent !== '');
    return issuesWithIndex;
  }

  function removeDuplicates(issues: IssueHightlight[]) {
    if (typeof issues !== 'object') return issues;
    const uniqueIssues = issues.filter(
      (issue, index, self) =>
        index === self.findIndex(t => t.issueContent === issue.issueContent && t.issueIndex === issue.issueIndex),
    );
    return uniqueIssues;
  }

  /**
   * Extracts the text from a span.
   * @param htmlString
   * @returns
   */
  function extractSpanText(htmlString: string) {
    const regex = /<span class=['"]description['"]>(.*?)<\/span>/;
    const match = htmlString.match(regex);
    return match ? match[1] : '';
  }

  /**
   * Extract context using the current rule violation.
   * @returns
   */
  function extractSectionWithContext(issueContext: string): { contextSlice: string; finalContext: string } {
    const cleanText = (text: string) => text.replace(/\s+/g, ' ').trim();

    let fullText = document.querySelector('#transcriptContent')!.textContent || '';

    if (issueContext && issueContext !== '') {
      fullText = issueContext;
    }

    // Clean the issueContent for case-insensitive matching, but preserve the original text.
    const normalizedSelectedText = cleanText(currentIssueContent || '').toLowerCase();

    // Convert fullText to lowercase for finding the section
    const normalizedFullText = cleanText(fullText).toLowerCase();

    // Find the first occurrence of the section in the text
    const sectionIndex = normalizedFullText.indexOf(normalizedSelectedText);

    let contextSlice = '';
    let finalContext = fullText;

    if (sectionIndex === -1) {
      console.error('Section not found in the text');
      return { contextSlice, finalContext }; // Return the original fullText to maintain original case
    }

    if (issueContext && issueContext !== '') {
      contextSlice = fullText;
    } else {
      // Calculate the starting and ending indices
      const originalTextLength = fullText.length;
      const start = Math.max(0, sectionIndex - 300);
      const end = Math.min(originalTextLength, sectionIndex + currentIssueContent.length + 300);

      // Extract the desired slice of the original text
      contextSlice = fullText.slice(start, end);
    }

    if (currentHighlightsArray) {
      finalContext = replaceRvOnContextByIndex(contextSlice, currentHighlightsArray, false);
    } else {
      finalContext = replaceRvOnContext(contextSlice, currentIssueContent);
    }

    return { contextSlice, finalContext };
  }

  /**
   * Function that sets a flag on the transcript component to wait for context selection. Also hides the modal and shows the context selection toast.
   */
  function changeContext() {
    dispatch(ruleViolationModalReducer.setShowModal(false));
    dispatch(submissionReducer.setIsSelectingContext(true));
    setErrorMessage('');
  }

  /**
   * Handles adding a new rule violation to the same text section
   */
  const addRow = () => {
    const newRow = { id: uuidv4() };

    setRows(prevState => [...prevState, newRow]);
    setDropdownStates(prevState => [
      ...prevState,
      {
        id: undefined,
        showDropdown: false,
        showRightPanel: false,
        outmostSelectedParentViolationRule: '',
        selectedViolationRuleValue: '',
        selectedParentViolationRule: '',
        rightPanelViolationRules: [],
        description: '',
        violationRuleId: null,
      },
    ]);
  };

  /**
   * Handle removal of a rule violation
   */
  const removeRow = (indexToRemove: number) => {
    setRows(prevState => prevState.filter((row, index) => index !== indexToRemove));
    setDropdownStates(prevState => prevState.filter((state, index) => index !== indexToRemove));
  };

  /**
   * Handles input change from the textarea to persist it to it's parent component
   * @param index
   * @param value
   */
  const handleDescriptionChange = (index: number, value: string) => {
    setDropdownStates(prevState =>
      prevState.map((state, i) => (i === index ? { ...state, description: value } : state)),
    );
  };

  /**
   * Clear the component state when hiding or after saving
   */
  const clearComponent = () => {
    dispatch(ruleViolationModalReducer.setShowModal(false));
    dispatch(ruleViolationModalReducer.clearModalState({}));
    setRows(initialRows);
    setDropdownStates([initialDropdownState]);
    setErrorMessage('');
  };

  /**
   * Handle modal submit: persist the rules changes to the database.
   * @param e
   */
  const handleSubmitModal = async (e: any, values: any, userReviewStatus: boolean) => {
    e.preventDefault();
    setErrorMessage('');

    try {
      const reviewIssueViolationRule: ReviewIssueViolationRule[] = values.map((item: ReviewIssueViolationRule) => ({
        id: item.id,
        description: item.description,
        violationRuleId: item.violationRuleId,
        userReviewStatus: true,
        reviewIssueId: reviewIssueId,
      }));
      const recordId = encodeURIComponent(currentRecord.sourceDate + currentRecord.sourceLocation);

      if (
        !currentHighlightsArray ||
        (currentHighlightsArray && currentHighlightsArray.length === 0) ||
        utils.isThereAtLeastOneHighlightThatIsNotInTheContext(currentContext, currentHighlightsArray)
      ) {
        dispatch(ruleViolationModalReducer.setCurrentHighlightsArray([]));
        setErrorMessage('Please, highlight the rule violation');
        return;
      }

      if (reviewIssueViolationRule.length === 1 && !reviewIssueViolationRule[0].violationRuleId) {
        setErrorMessage('You must to add at least one rule and select the rule violation for it');
        return;
      }

      if (!reviewIssueViolationRule.every(item => item.violationRuleId)) {
        setErrorMessage('All rules must have rule violation selected');
        return;
      }

      if (isRequesting) {
        return;
      }

      const shouldGetSuggestedContextTimes = currentContext === suggestedContext;

      const contextTime = getContextTimes(shouldGetSuggestedContextTimes);

      const reviewIssueData: ReviewIssue = {
        timeStart: contextTime.contextTimeStart,
        timeEnd: contextTime.contextTimeEnd,
        issueContext: currentContext,
        issueContent: currentIssueContent,
        userReviewStatus,
        id: reviewIssueId,
        issues: removeWithNoIndex(currentHighlightsArray),
      };

      setIsRequesting(true);

      if (reviewIssueData.id) {
        handleSubmissionIssueRequest(
          submissionService.updateSubmissionIssue,
          updateSubmissionReview,
          reviewIssueData,
          recordId,
          reviewIssueViolationRule,
        );
      } else {
        handleSubmissionIssueRequest(
          submissionService.createSubmissionIssue,
          updateNewSubmissionReview,
          reviewIssueData,
          recordId,
          reviewIssueViolationRule,
        );
      }
    } catch (err: any) {
      setErrorMessage(`Unknown error: ${err.message}`);
      setIsRequesting(false);
    }
  };

  /**
   * Pre handles the actual submission to the API.
   * @param serviceFunction
   * @param updateFunction
   */
  function handleSubmissionIssueRequest(
    serviceFunction: any,
    updateFunction: any,
    reviewIssueData: ReviewIssue,
    recordId: string,
    reviewIssueViolationRule: ReviewIssueViolationRule[],
  ) {
    serviceFunction(reviewIssueData, recordId, reviewIssueViolationRule)
      .promise.then((submissionIssue: SubmissionIssueDTO) => {
        updateFunction(submissionIssue);
        clearComponent();

        const contextTime = getContextTimes(false);
        utils.selectTranscriptSection(contextTime.contextTimeStart);
        dispatch(transcriptReducer.setByPassSync(true));
      })
      .catch((err: Error) => {
        if (err.message.includes('Network Error')) {
          setErrorMessage(
            `It seems we're having trouble connecting to the network at the moment.
            Please check your internet connection and try again.`,
          );
        } else if (err.message.includes('401')) {
          setErrorMessage(`${AlertMessages.HTTP_401}`);
        } else {
          setErrorMessage(`Unknown error: ${err.message}`);
        }
      })
      .finally(() => {
        setIsRequesting(false);
      });
  }

  /**
   * Update an edited violation rule.
   * @param reviewIssue
   */
  function updateSubmissionReview(submissionIssue: SubmissionIssueDTO) {
    const updatedSubmissionReviews: SubmissionIssueDTO[] = [...submissionReviews];
    const submissionReviewIndexToBeUpdated = updatedSubmissionReviews.findIndex(
      sr => sr.reviewIssue.id === submissionIssue.reviewIssue.id,
    );

    if (submissionReviewIndexToBeUpdated !== -1) {
      updatedSubmissionReviews[submissionReviewIndexToBeUpdated] = { ...submissionIssue };
      dispatch(submissionReducer.setSubmissionReviews(updatedSubmissionReviews));
    }
  }

  /**
   * Insert the new issue created by using the transcript selection into the review tab.
   */
  function updateNewSubmissionReview(submissionIssue: SubmissionIssueDTO) {
    dispatch(
      submissionReducer.setSubmissionReviews(
        submissionReviews.concat([
          {
            reviewIssue: submissionIssue.reviewIssue,
            reviewIssueViolationRule: submissionIssue.reviewIssueViolationRule,
          },
        ]),
      ),
    );
  }

  /**
   * Reset all the highlights so the user can start selecting them again.
   * @param e
   */
  const handleResetHightlight = async (e: any) => {
    if (currentContext !== '') {
      setFullContext(currentContext);
    } else {
      setFullContext(originalContext);
    }
    dispatch(ruleViolationModalReducer.setCurrentHighlightsArray([]));
  };

  function getContextTimes(shouldGetSuggestedContextTimes: boolean) {
    let contextTimeStart = timeStart;
    let contextTimeEnd = timeEnd;

    if (contextTimeStart != null && contextTimeEnd != null && !shouldGetSuggestedContextTimes)
      return { contextTimeStart, contextTimeEnd };

    const contextIndex = mergedTranscript.toLowerCase().indexOf(currentContext.toLowerCase());
    const timestampString = utils.stringTimestamp(mergedSegments);
    let sections = timestampString.substring(0, contextIndex + 5);
    let sectionsUntilContextEnd = timestampString.substring(0, contextIndex + currentContext.length + 5);
    let splittedSections = utils.getSections(sections);
    let splittedLastSections = utils.getSections(sectionsUntilContextEnd);

    if (splittedSections && splittedSections.length) {
      let firstSection = parseInt(splittedSections.at(-1) || timeStart);
      let lastSection = parseInt(splittedLastSections!.at(-1) || timeEnd);
      let firstSegment = mergedSegments.filter((segment: any) => segment.start === firstSection);
      let lastSegment = mergedSegments.filter((segment: any) => segment.start === lastSection);
      if (firstSegment && firstSegment.length) {
        contextTimeStart = firstSegment[0].start;
      }
      if (lastSegment && lastSegment.length) {
        contextTimeEnd = lastSegment[0].end;
      }
    }

    return { contextTimeStart, contextTimeEnd };
  }

  function clearDropdownStates(dropdownStates: Array<DropdownState>): Array<DropdownState> {
    return dropdownStates.filter((dropdownState: DropdownState) => dropdownState.id !== undefined);
  }

  return (
    <Modal
      size="lg"
      centered
      show={showModal}
      onHide={clearComponent}
      aria-labelledby="AddRuleViolationModal"
      className="violationRuleModal">
      <Modal.Header closeButton>
        <Modal.Title className="modalTitle" id="AddRuleViolationModal">
          {mode === 'issue' ? 'Edit Rule Violations' : 'Add Rule Violation'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalBody">
        {errorMessage && (
          <AlertComponent text={errorMessage} type="danger" buttonText="X" onClick={() => setErrorMessage('')} />
        )}
        <div className="contextHeader">
          <p className="title">Highlight Rule Violation</p>
          <div className="actions">
            <Button
              className="button resetHighlight"
              onClick={e => {
                handleResetHightlight(e);
              }}>
              Reset Highlights
            </Button>
            <p className="changeContext" onClick={changeContext}>
              Change Context
            </p>
          </div>
        </div>

        <div className="context">{parse(fullContext)}</div>

        <div className="rule-violation-container">
          <p className="title">Add Rules</p>
          <div className="ruleViolationContainer">
            {rows.map((row, index) => (
              <div className="row" key={row.id}>
                <div className="selectionSection">
                  <Dropdown
                    show={dropdownStates[index].showDropdown}
                    onToggle={isOpen => handleToggle(index, isOpen)}
                    autoClose={false}>
                    <Dropdown.Toggle id="dropdown-basic">
                      {dropdownStates[index].selectedViolationRuleValue || 'Select a new Rule Violation'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      style={{
                        background: dropdownStates[index].rightPanelViolationRules.length ? '#F2F2F2' : 'white',
                      }}>
                      {nestedViolationRules?.map((parentRule: ViolationRule) => (
                        <React.Fragment key={parentRule.id}>
                          <Dropdown.Header>{parentRule.name}</Dropdown.Header>
                          {parentRule.children?.map(rule => {
                            if (
                              Array.isArray(dropdownStates) &&
                              !dropdownStates.some(violationRule => violationRule.violationRuleId === rule.id)
                            ) {
                              return (
                                <Dropdown.Item
                                  key={rule.id}
                                  onClick={() => violationRuleClick(rule, index, parentRule.name)}>
                                  {rule.name}
                                </Dropdown.Item>
                              );
                            }
                            return null;
                          })}
                        </React.Fragment>
                      ))}
                    </Dropdown.Menu>
                    {dropdownStates[index].showRightPanel && (
                      <div
                        className="right-panel"
                        style={{
                          background: dropdownStates[index].rightPanelViolationRules.length ? 'white' : '#F2F2F2',
                        }}>
                        {dropdownStates[index].rightPanelViolationRules.map((childRule: ViolationRule) => {
                          if (!dropdownStates?.some(violationRule => violationRule.violationRuleId === childRule.id)) {
                            return (
                              <Dropdown.Item key={childRule.id} onClick={() => violationRuleClick(childRule, index)}>
                                {childRule.name}
                              </Dropdown.Item>
                            );
                          }
                          return null;
                        })}
                      </div>
                    )}
                  </Dropdown>
                  <textarea
                    className="ruleViolationDescription"
                    id={`formControlTextarea_${index}`} // Unique ID for each textarea
                    rows={4}
                    value={dropdownStates[index].description.replaceAll('double_quotes', '"')}
                    disabled={dropdownStates[index].selectedViolationRuleValue ? false : true}
                    placeholder="Select a rule in order to add the description..."
                    onChange={e => handleDescriptionChange(index, e.target.value)}
                  />
                </div>
                <div className="controlSection">
                  {index === rows.length - 1 ? (
                    <div className="last-item">
                      {index != 0 ? <CiCircleMinus onClick={() => removeRow(index)} className="remove" /> : <></>}
                      <CiCirclePlus onClick={addRow} className="add" />
                    </div>
                  ) : (
                    <CiCircleMinus onClick={() => removeRow(index)} className="remove" />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="rv-modal-footer">
        <div className="rvModalActions">
          <div className="leftContent">
            {mode === 'issue' ? (
              <Button
                className="button disagreeButton"
                hidden={true}
                onClick={e => {
                  handleSubmitModal(e, dropdownStates, false);
                }}>
                Disagree with All
              </Button>
            ) : (
              <></>
            )}
          </div>
          <div className="rightContent">
            <Button
              className="button submitButton"
              onClick={e => {
                handleSubmitModal(e, dropdownStates, true);
              }}>
              {isRequesting ? (
                <LoaderComponent styles={{ border: '0.2em solid white', borderTop: '0.2em solid transparent' }} />
              ) : mode === 'issue' ? (
                'Agree with All'
              ) : (
                'Save'
              )}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
