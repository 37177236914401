import './index.scss';
import { LogoLoading, Select } from '@amway/react-components';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getColour } from './utils';
import { Container, Card, Button } from 'react-bootstrap';
import { InputLabel } from '@mui/material';
import AnalystDecision from './analyst-decision';
import SummaryTab from './summary-tab';
import TranscriptTab from './transcript-tab';
import ReviewTab from './review-tab';
import ReviewHistoryTab from './review-history-tab';
import { BiSolidUpArrow } from 'react-icons/bi';
import { capitalizeFirstLetter } from '../../utils/string-utils';
import usePipelineOutput from '../../resources/pipeline-output/pipeline-output-hook';
import { IPipelineOutput, SubmissionIssueDTO } from '../../resources/pipeline-output/pipeline-output-types';
import { formatSourceDate } from '../dashboard/utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  setIsReviewAlertActive,
  setReviewAlertTimeout,
  setCurrentRecord,
  setSubmissionReviews,
  setReviews,
  changeCurrentTab,
} from '../../redux/reducers/submissionReducer';
import { ReviewIssueViolationRule } from '../../resources/submission/submission-types';
import { Tab } from '../../interface/tab';
import { Review } from '../../interface/review';
import { hasTheReviewBeenSent } from '../../helpers/reviews-helper';

export default function SubmissionComponent() {
  const { fetchPipelineOutputDetails, pipelineOutputDetails } = usePipelineOutput();
  const [selectValue, setSelectValue] = useState('');
  const initialized = useRef(false)

  const dispatch = useAppDispatch();
  const { submissionReviews, currentRecord, currentTab } = useAppSelector(state => state.submission);
  const reviews: Review[] = useAppSelector(state => state.submission.reviews);
  const reviewsheetHistoryTabShouldBeDisplayed = reviews.some(review => hasTheReviewBeenSent(review));
  const tabs = [
    { id: 0, name: 'Summary', show: true },
    { id: 1, name: 'Transcript', show: true },
    { id: 2, name: 'Review', show: true },
    { id: 3, name: 'Review History', show: reviewsheetHistoryTabShouldBeDisplayed },
  ] as Tab[];

  const videoRef = useRef<HTMLVideoElement>(null);
  const queryString = window.location.search; // Get the query string from the URL
  const searchParams = new URLSearchParams(queryString); // Create a URLSearchParams object to parse the query string
  const defaultId = searchParams.get('id'); // Get the value of the "id" parameter
  const id = defaultId;

  const records = useMemo(() => {
    if (!pipelineOutputDetails.data) return [];
    return pipelineOutputDetails.data?.submissions;
  }, [pipelineOutputDetails]);

  const selectOptions = useMemo(() => {
    if (!pipelineOutputDetails.data) return [];
    return pipelineOutputDetails.data?.submissions.map(f => ({
      name: formatSourceDate(f.sourceDate) + ' - ' + f.sourceLocation.split('/').reverse()[0],
      value: f.sourceLocation,
    }));
  }, [pipelineOutputDetails]);

  const setSelectedFile = useCallback((file: IPipelineOutput) => {
    dispatch(setCurrentRecord(file));
    videoRef.current?.load();
  }, []);

  useEffect(() => {
    if (!initialized.current) {
      // Avoiding duplicated calls on page start
      initialized.current = true
      fetchPipelineOutputDetails(String(id));
    }
  }, []);

  useEffect(() => {
    if (pipelineOutputDetails.data) {
      setSelectValue(pipelineOutputDetails.data?.submissions[0].sourceLocation);
      setSelectedFile(pipelineOutputDetails.data?.submissions[0]);
      dispatch(setSubmissionReviews(pipelineOutputDetails.data?.submissionsReviews[0]));
      dispatch(setReviews(pipelineOutputDetails.data?.reviews[0]));
    }
  }, [pipelineOutputDetails.data, setSelectedFile]);

  /**
   * Event triggered when the user changes submission version.
   * @param event
   */
  function handleVersionChange(event: any) {
    const selectedValue = event.target.value;

    // Find the index of the selected item in the records array
    const selectedIndex = records.findIndex(f => f.sourceLocation === selectedValue);

    // If the selected item is found, set the selected file and submission reviews
    if (selectedIndex !== -1) {
      setSelectValue(selectedValue);
      setSelectedFile(records[selectedIndex]);
      dispatch(setSubmissionReviews(pipelineOutputDetails.data?.submissionsReviews[selectedIndex] || []));
      dispatch(setReviews(pipelineOutputDetails.data?.reviews[selectedIndex]));
    }
  }

  function canSubmitReview() {
    return submissionReviews?.every((submissionReview: SubmissionIssueDTO) => {
      const userReviewStatus = submissionReview.reviewIssueViolationRule.every(
        (issue: ReviewIssueViolationRule) => issue.userReviewStatus !== null,
      );

      return userReviewStatus;
    });
  }

  function handleReviewTab() {
    if (canSubmitReview()) {
      const reviewTab = tabs.find(tab => tab.name === 'Review') as Tab;
      dispatch(changeCurrentTab(reviewTab));
    } else {
      const transcriptTab = tabs.find(tab => tab.name === 'Transcript') as Tab;
      dispatch(changeCurrentTab(transcriptTab));
      dispatch(setIsReviewAlertActive(true));
      const reviewAlertTimeout = setTimeout(() => {
        dispatch(setIsReviewAlertActive(false));
      }, 120000);
      dispatch(setReviewAlertTimeout(reviewAlertTimeout));
    }
  }

  function activeTab(tabId: number) {
    return currentTab.id === tabId;
  }

  function handleTabClick(tab: Tab) {
    if (tab.name === 'Review') {
      handleReviewTab();
    } else {
      dispatch(changeCurrentTab(tab));
    }
  }

  return (
    <Container>
      {selectValue !== '' ? (
        <Card>
          <Card.Body>
            <div className="submission-title-container">
              <h4 className="submission-title">"{currentRecord?.titleEnglish}"</h4>
              <div className="submission-version-container">
                <InputLabel id="selectVersion" className="submission-select-version-label">
                  Version:
                </InputLabel>
                <Select
                  id="selectVersion"
                  className="select-version"
                  onChange={handleVersionChange}
                  options={selectOptions}
                  value={selectValue}
                  placeholder=""
                  required
                  size="sm"
                  variant="secondary"
                />
              </div>
            </div>

            <div className="submission-header-container">
              <div className="line-before-tab"></div>
              <div className="tab-buttons">
                {tabs.map((tab: Tab, index: number) => (
                  <Button
                    className={`tab-button ${activeTab(index) ? 'active' : ''}`}
                    hidden={!tab.show}
                    onClick={() => handleTabClick(tab)}
                    key={index}>
                    <div>{tab.name}</div>
                  </Button>
                ))}
              </div>

              {currentRecord && (
                <div className="decision">
                  <div className="decision-details">
                    <span className="decision-title">ML Recomendation:</span>
                    <span className={'decision-value ' + getColour(currentRecord.modelDecision)}>
                      {capitalizeFirstLetter(currentRecord.modelDecision)}
                    </span>

                    <span className="decision-title">Confidence Level:</span>
                    <span className="decision-value">{capitalizeFirstLetter(currentRecord.decisionConfidence)}</span>
                    {currentRecord.decisionConfidence ? (
                      <BiSolidUpArrow className={'level' + getColour(currentRecord.decisionConfidence)} />
                    ) : (
                      <span></span>
                    )}

                    <span className="decision-title">Reason:</span>
                    <span className="decision-value">{capitalizeFirstLetter(currentRecord.decisionReason)}</span>
                  </div>
                  <div>
                    <AnalystDecision />
                  </div>
                </div>
              )}
            </div>

            <div className="submission-content">
              {currentTab.name === 'Summary' && <SummaryTab />}

              {currentTab.name === 'Transcript' && <TranscriptTab />}

              {currentTab.name === 'Review' && <ReviewTab />}

              {currentTab.name === 'Review History' && <ReviewHistoryTab />}
            </div>
          </Card.Body>
        </Card>
      ) : (
        <div className="loading-screen-submission">
          <LogoLoading />
        </div>
      )}
    </Container>
  );
}
