import React from 'react';

function KeywordIcon({ fill }) {
  return (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.0389 5.56775L7.52483 10.8114L5.02337 8.42617L4.06232 9.34009L7.52483 12.6393L14 6.48167M2.70594 5.18534L4.11685 1.62042L5.52775 5.18534M6.80915 8.42617H8.23369L4.75073 0H3.48296L0 8.42617H1.42454L2.18793 6.48167H6.03213L6.80915 8.42617Z"
        fill={fill}
      />
    </svg>
  );
}

export default KeywordIcon;
