import Select, { ActionMeta, SingleValue } from 'react-select';
import './custom-language-select.scss';

export interface CustomOptionType {
  label: string;
  value: string;
  languageCode: string;
}

export const selectDefaultOption = { value: '0', label: 'English', languageCode: 'en-us' };

const customOption = (option: CustomOptionType) => (
  <div className="opt">
    {option.languageCode !== '' ? <span className={`lang-icon lang-icon-${option.languageCode}`}></span> : <></>}
    {option.label}
  </div>
);

interface CustomLanguageSelectProps {
  selectedOption: CustomOptionType;
  options: CustomOptionType[];
  isDisabled: boolean;
  handleChange: (newValue: SingleValue<CustomOptionType>, actionMeta: ActionMeta<CustomOptionType>) => void;
}

export default function CustomLanguageSelect({
  options,
  isDisabled,
  selectedOption,
  handleChange,
}: CustomLanguageSelectProps) {
  return (
    <Select
      className="custom-language-select"
      value={selectedOption}
      onChange={handleChange}
      options={options}
      getOptionLabel={option => option.label}
      getOptionValue={option => option.value}
      formatOptionLabel={customOption}
      isDisabled={isDisabled}
      isSearchable={false}
    />
  );
}
