import { configureStore } from '@reduxjs/toolkit';
import submissionReducer from '../reducers/submissionReducer';
import transcriptReducer from '../reducers/transcriptReducer';
import ruleViolationModalReducer from '../reducers/ruleViolationModalReducer';

export const store = configureStore({
  reducer: {
    submission: submissionReducer,
    transcript: transcriptReducer,
    ruleViolationModal: ruleViolationModalReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
