import React, { useState } from 'react';
import { ContentContext } from './content-context';

interface ContentProviderProps {
  children: React.ReactNode;
}

const ContentProvider: React.FC<ContentProviderProps> = ({ children }) => {
  //////////////////////////////////////////////////////////////////////////
  //mock data
  const sourceOptions = ['FB', 'INSTA', 'LinkedIn', 'Creators', 'YouTube'];
  const statusOptions = ['Accepted', 'Rejected', 'Take Down', 'Need Review'];
  const resultOptions = ['Yes', 'No'];
  const confidenceOptions = ['95%', '80%', '63%', '47%', '22%', '6%'];
  function getRandomDateAsString() {
    // Generate a random timestamp within a specific range (adjust the range as needed)
    const startDate = new Date(2000, 0, 1); // January 1, 2000
    const endDate = new Date(); // Current date
    const randomTimestamp = new Date(startDate.getTime() + Math.random() * (endDate.getTime() - startDate.getTime()));

    // Convert the random timestamp to a string
    const randomDateString = randomTimestamp.toDateString();

    return randomDateString;
  }
  function getRandomValue(arr: any) {
    // Generate a random index between 0 and the length of the array (exclusive)
    const randomIndex = Math.floor(Math.random() * arr.length);

    // Return the element at the random index
    return arr[randomIndex];
  }

  const [content, setContent] = useState('');

  return (
    <ContentContext.Provider
      value={{
        content,
        setContent,
      }}>
      {children}
    </ContentContext.Provider>
  );
};

export default ContentProvider;
