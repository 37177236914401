import { createContext } from 'react';
import {
  IAffiliate,
  IAssignedTo,
  IPipelineOutput,
  IPipelineOutputDetails,
  IPipelineOutputRequest,
  IStatus,
  ISubmitter,
} from './pipeline-output-types';
import { ExternalData } from '../../@types/external-api';

export interface PipelineOutputCtxType {
  pipelineOutputResults: ExternalData<IPipelineOutput[]>;
  allAffiliates: ExternalData<IAffiliate[]>;
  allSubmitters: ExternalData<ISubmitter[]>;
  allStatus: ExternalData<IStatus[]>;
  allAssignedTo: ExternalData<IAssignedTo[]>;
  pipelineOutputDetails: ExternalData<IPipelineOutputDetails>;
  fetchPipelineOutputDetails: (id: string) => VoidFunction | undefined;
  fetchPipelineOutputResults: (request: IPipelineOutputRequest) => VoidFunction | undefined;
  fetchAllAffiliates: () => VoidFunction | undefined;
  fetchAllSubmitters: () => VoidFunction | undefined;
  fetchAllStatus: () => VoidFunction | undefined;
  fetchAllAssignedTo: () => VoidFunction | undefined;
}

const PipelineOutputContext = createContext<PipelineOutputCtxType>(null as any);

export default PipelineOutputContext;
