import { Autocomplete, AutocompleteRenderOptionState, CircularProgress, TextField } from '@mui/material';
import debounce from 'lodash.debounce';
import { HTMLAttributes, useCallback, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Item } from '../dropdown-btn';
import './index.scss';

type Props = {
  id: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  items?: Item[];
  selectedValue?: Item | null;
  styleType?: 'default' | 'filter';
  required?: boolean;
  search: (search: string) => Promise<Item[]>;
  selectValue: (selected: Item) => void;
};

export default function AsyncAutocompleteComponent({
  id,
  styleType = 'default',
  label,
  placeholder,
  disabled,
  items = [],
  selectedValue,
  required = false,
  search,
  selectValue,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<Item[]>(items);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setOptions(items);
  }, [items]);

  const performSearch = useCallback(
    (value?: string) => {
      setLoading(true);
      return search(value ?? '').then(items => {
        setOptions(items);
        setLoading(false);
        return items;
      });
    },
    [search],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleKeyStroke = useCallback(
    debounce((_, value: string) => {
      performSearch(value);
    }, 750),
    [performSearch],
  );

  const handleSelection = useCallback(
    (_: any, value: any) => {
      selectValue(value);
    },
    [selectValue],
  );

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <Form.Group className={'async-autocomplete ' + styleType}>
      {label && (
        <Form.Label htmlFor={id} className="autocomplete-form-label">
          {label} {required && <sup style={{ color: 'var(--warning-error)' }}>*</sup>}
        </Form.Label>
      )}
      <Autocomplete
        id={id}
        value={selectedValue}
        disabled={disabled}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={option => option.label}
        options={options}
        loading={loading}
        onChange={handleSelection}
        onInputChange={handleKeyStroke}
        filterOptions={x => x}
        renderOption={(props: HTMLAttributes<HTMLLIElement>, option: Item, state: AutocompleteRenderOptionState) => (
          <Row
            as="li"
            {...props}
            className="MuiAutocomplete-option justify-content-between"
            style={{
              fontFamily: 'Lato',
              color: 'var(--text-black)',
            }}>
            <Col md={option.secondLabel ? 7 : undefined}>{option.label}</Col>
            {option.secondLabel ? (
              <Col md={1} style={{ color: 'var(--text-gray)', paddingRight: '0x' }}>
                <span>{option.secondLabel}</span>
              </Col>
            ) : (
              <></>
            )}
            <Col
              md={option.secondLabel ? 4 : undefined}
              style={{ color: 'var(--text-gray)', paddingRight: '0x', textAlign: 'right' }}>
              <span>{option.thirdLabel}</span>
            </Col>
          </Row>
        )}
        renderInput={params => (
          <TextField
            placeholder={placeholder}
            {...params}
            required={required}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Form.Group>
  );
}
