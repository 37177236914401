import axios from 'axios';
import { WithAbortFn } from '../../@types/external-api';
import { apiWrapper } from '../../config/api-wrapper';
import { authorizationMapper, claimsPermissionsMapper, featuresMapMapper } from './features-mapper';
import { Authorization, ClaimPermissions, FeaturesMap, Permission } from './features-types';

class PermissionService {
  public getAuthorization(): WithAbortFn<Promise<Authorization>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api
        .get('/user/info')
        .then(res => res.data)
        .then(data => authorizationMapper(data)),
      abort: source.cancel,
    };
  }

  public update(permissions: Permission[]): Promise<void> {
    return apiWrapper.api.post('/user/permissions', { permissions });
  }

  public listClaimsPermissions(): Promise<ClaimPermissions[]> {
    return apiWrapper.api.get('/user/permissions/claims').then(res => claimsPermissionsMapper(res.data));
  }

  public getFeaturesMap(): Promise<FeaturesMap> {
    return apiWrapper.api.get('/user/permissions/features').then(res => featuresMapMapper(res.data));
  }
}

export default new PermissionService();
