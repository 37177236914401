import axios from 'axios';
import { WithAbortFn } from '../../@types/external-api';
import { apiWrapper } from '../../config/api-wrapper';
import {
  IAffiliate,
  IAssignedTo,
  IPipelineOutput,
  IPipelineOutputDetails,
  IPipelineOutputRequest,
  IStatus,
  ISubmitter,
  SubmissionIssueDTO,
} from './pipeline-output-types';
import { affiliateMapper, assignedToMapper, statusMapper } from './pipeline-output-mapper';
import { IssueHightlight } from '../submission/submission-types';
import * as utils from '../../screens/submission/utils';
import { Option } from '../../interface/select-types';

class PipelineOutputService {
  public getAllPipelineOutputs({
    sortCol,
    sortOrder,
    search,
    submitterName,
    affiliateId,
    status,
    assignedToName,
  }: IPipelineOutputRequest): WithAbortFn<Promise<IPipelineOutput[]>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api
        .get('/pipeline-output', {
          params: {
            sort: sortCol,
            sortOrder,
            search,
            submitterName: (submitterName ?? '').length > 0 ? submitterName : undefined,
            affiliateId: (affiliateId ?? '').length > 0 ? affiliateId : undefined,
            status: (status ?? '').length > 0 ? status : undefined,
            assignedToName: (assignedToName ?? '').length > 0 ? assignedToName : undefined,
          },
          cancelToken: source.token,
        })
        .then((res: any) => res.data),
      abort: source.cancel,
    };
  }

  public getPipelineOutputDetails(id: string): WithAbortFn<Promise<IPipelineOutputDetails>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api
        .get(`/pipeline-output/${id}`, {
          cancelToken: source.token,
        })
        .then(res => res.data)
        .then((pipelineOutputDetails: IPipelineOutputDetails) => {
          const updatedSubmissions = pipelineOutputDetails.submissions.map((submission: IPipelineOutput) => ({
            ...submission,
            transcriptVersions: this.mapTranscriptVersions(submission.transcriptVersions),
            typeName: this.mapTypeName(submission.typeName),
          }));
          const updatedSubmissionReviews: SubmissionIssueDTO[][] = this.mapSubmissionsReviews(
            pipelineOutputDetails.submissionsReviews,
            pipelineOutputDetails.submissions,
          );

          return {
            ...pipelineOutputDetails,
            submissions: updatedSubmissions,
            submissionsReviews: updatedSubmissionReviews,
          } as IPipelineOutputDetails;
        }),
      abort: source.cancel,
    };
  }

  public getAllAffiliates(): WithAbortFn<Promise<IAffiliate[]>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api
        .get('/pipeline-output/available-affiliates', {
          cancelToken: source.token,
        })
        .then((res: any) => res.data)
        .then((data: any) => affiliateMapper(data)),
      abort: source.cancel,
    };
  }

  public getAllStatus(): WithAbortFn<Promise<IStatus[]>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api
        .get('/pipeline-output/all-status', {
          cancelToken: source.token,
        })
        .then((res: any) => res.data)
        .then((data: any) => statusMapper(data)),
      abort: source.cancel,
    };
  }

  public getAllSubmitters(): WithAbortFn<Promise<ISubmitter[]>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api
        .get('/pipeline-output/all-submitters', {
          cancelToken: source.token,
        })
        .then((res: any) => res.data)
        .then((data: any) => statusMapper(data)),
      abort: source.cancel,
    };
  }

  public getAllAssignedsAvailable(): WithAbortFn<Promise<IAssignedTo[]>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api
        .get('/pipeline-output/all-assigned', {
          cancelToken: source.token,
        })
        .then((res: any) => res.data)
        .then((data: any) => assignedToMapper(data)),
      abort: source.cancel,
    };
  }

  public saveAnalystDecision(
    recordId: string,
    sourceLocation: string,
    sourceDate: string,
    analystDecision: string,
  ): WithAbortFn<Promise<any>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api
        .put(
          `/pipeline-output/analyst-decision/${recordId}?sourceLocation=${sourceLocation}&sourceDate=${sourceDate}&analystDecision=${analystDecision}`,
        )
        .then((res: any) => res.data),
      abort: source.cancel,
    };
  }

  private mapTypeName(typeName: string): string {
    const lowerCasedTypeName = typeName.toLowerCase();
    const typeMap: { [key: string]: string } = {
      audio: 'Audio',
      video: 'Video',
      youtube: 'Video',
    };

    return typeMap[lowerCasedTypeName] || 'Audio';
  }

  private mapTranscriptVersions(transcriptVersions: Array<Option>): Array<Option> {
    return transcriptVersions.map((v: any) => ({
      value: v.versionNumber,
      label: utils.formatDate(v.createdAt),
    }));
  }

  private mapSubmissionsReviews(
    submissionsReviews: SubmissionIssueDTO[][],
    submissions: IPipelineOutput[],
  ): SubmissionIssueDTO[][] {
    const updatedSubmissionReviews: SubmissionIssueDTO[][] = [...submissionsReviews];

    submissionsReviews.forEach((submissionsReviewList: SubmissionIssueDTO[], submissionIndex: number) => {
      submissionsReviewList.forEach((submissionReview: SubmissionIssueDTO, submissionReviewIndex: number) => {
        const issues = submissionReview.reviewIssue.issues;
        const constTimeStart = submissionReview.reviewIssue.timeStart;
        const issueContent = submissionReview.reviewIssue.issueContent;

        let segments = submissions[submissionIndex].segments;

        if (typeof submissions[submissionIndex].segments === 'string') {
          segments = JSON.parse(submissions[submissionIndex].segments.toString());
        }

        const merge = utils.mergeSegmentsAndTranscript(segments);
        const timestampString = utils.stringTimestamp(merge.mergedSegments);

        updatedSubmissionReviews[submissionIndex][submissionReviewIndex] = {
          reviewIssueViolationRule: submissionReview.reviewIssueViolationRule,
          reviewIssue: {
            ...submissionReview.reviewIssue,
            issues: issues ? (JSON.parse(issues.toString()) as IssueHightlight[]) : [],
            timeStart:
              constTimeStart === null
                ? utils.findIssueStartTime(timestampString, merge.transcript, issueContent).startTime
                : constTimeStart,
          },
        };
      });
    });

    return updatedSubmissionReviews;
  }
}

const pipelineOutputService = new PipelineOutputService();
export default pipelineOutputService;
