import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AuthProvider from './resources/auth/auth-provider';
import FeaturesProvider from './resources/features/features-provider';
import Router from './router';
import ContentProvider from './resources/content/content-provider';
import PipelineOutputProvider from './resources/pipeline-output/pipeline-output-provider';
import ViolationRuleProvider from './resources/violation-rule/violation-rule-provider';
import { appTitle } from './config/env';

export default function App() {
  useEffect(() => {
    document.title = appTitle;
  }, [appTitle]);

  return (
    <BrowserRouter>
      <AuthProvider>
        <FeaturesProvider>
          <ContentProvider>
            <PipelineOutputProvider>
              <ViolationRuleProvider>
                <Router />
              </ViolationRuleProvider>
            </PipelineOutputProvider>
          </ContentProvider>
        </FeaturesProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}
