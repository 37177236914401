import { SubmissionIssueDTO } from '../resources/pipeline-output/pipeline-output-types';

export function sortByTimeStart(a: SubmissionIssueDTO, b: SubmissionIssueDTO) {
  // If both have timeStart, sort by timeStart ASC
  if (
    a.reviewIssue.timeStart !== null &&
    a.reviewIssue.timeStart !== undefined &&
    b.reviewIssue.timeStart !== null &&
    b.reviewIssue.timeStart !== undefined
  ) {
    return a.reviewIssue.timeStart - b.reviewIssue.timeStart;
  }

  // If one of them has timeStart, prioritize the one without timeStart
  if (a.reviewIssue.timeStart !== null && a.reviewIssue.timeStart !== undefined) {
    return 1;
  }
  if (b.reviewIssue.timeStart !== null && b.reviewIssue.timeStart !== undefined) {
    return -1;
  }

  // If neither have timeStart, sort by id
  return a.reviewIssue.id! - b.reviewIssue.id!;
}

export function sortByTimeStartDESC(a: SubmissionIssueDTO, b: SubmissionIssueDTO) {
  // If both have timeStart, sort by timeStart ASC
  if (
    a.reviewIssue.timeStart !== null &&
    a.reviewIssue.timeStart !== undefined &&
    b.reviewIssue.timeStart !== null &&
    b.reviewIssue.timeStart !== undefined
  ) {
    return b.reviewIssue.timeStart - a.reviewIssue.timeStart;
  }

  // If one of them has timeStart, prioritize the one without timeStart
  if (a.reviewIssue.timeStart !== null && a.reviewIssue.timeStart !== undefined) {
    return -1;
  }
  if (b.reviewIssue.timeStart !== null && b.reviewIssue.timeStart !== undefined) {
    return 1;
  }

  // If neither have timeStart, sort by id
  return b.reviewIssue.id! - a.reviewIssue.id!;
}
