import { Duration } from 'luxon';

export function formatVideoTime(seconds: number) {
  const duration = Duration.fromObject({ seconds: Number(seconds) });
  return duration.toFormat('mm:ss');
}

export function videoTimeToSeconds(videoTime: string) {
  if (!videoTime) return 0;
  const collonIndex = videoTime.indexOf(':');
  if ( collonIndex === -1 ) return parseInt(videoTime);

  const minutes = Number(videoTime.substring(0, collonIndex));
  const seconds = Number(videoTime.substring(collonIndex + 1, videoTime.length));
  const duration = minutes * 60 + seconds;
  return duration;
}
