/* eslint-disable react-hooks/exhaustive-deps */
//TODOS:
// - Add blue background to selected row
// - Make table head fixed to its position when scrolled
// - change cursor when on top of a row

import { Card, Col, Container, Row } from 'react-bootstrap';
import './dashboard.scss';
import { Typography, IconButton } from '@amway/react-components';
import { useState, useEffect, useCallback } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { BsChevronDown, BsChevronUp, BsCaretDownFill, BsCaretUpFill } from 'react-icons/bs';
import { IOption } from '../../components/ui/multi-select-dropdown';
import { getFiltersFromStorage } from '../../helpers/filters-local-storage';

// import stringSimilarity from 'string-similarity'
import { formatSourceDate, tableCols } from './utils';
import { capitalizeFirstLetter } from '../../utils/string-utils';
import usePipelineOutput from '../../resources/pipeline-output/pipeline-output-hook';
import {
  IPipelineOutput,
  IPipelineOutputRequestSortOrder,
} from '../../resources/pipeline-output/pipeline-output-types';
import ExternalDataRenderer from '../../components/hocs/external-data-renderer';
import TableLoadingComponent from '../../components/ui/table-loading-component';
import DashboardFilters from './dashboard-filters/dashboard-filters';
import useAuth from '../../resources/auth/auth-hook';

interface DashboardRow {
  id: string;
  // source: any,
  date: any;
  result?: any;
  confidence?: any;
  summary?: string;
  creator?: string;
  selected: boolean;
  englishTitle: string;
  assignedTo: string;
  submitterName: string;
  sku: string;
  speakerFullName: string[];
  status: string;
  applyCode: string;
  typeName: string;
  open: boolean;
  modelDecision: string;
  decisionConfidence: string;
}

export default function DashboardComponent() {
  const columnTitleStyle = { fontWeight: 'bold' };
  const defaultFilters = getFiltersFromStorage();

  const [summary] = useState();
  const [sortCol, setSortCol] = useState<string>('entry_date');
  const [sortOrder, setSortOrder] = useState<IPipelineOutputRequestSortOrder>('DESC');
  const [selectedAffiliate, setSelectedAffiliate] = useState<IOption>(defaultFilters.affiliate);
  const [selectedStatus, setSelectedStatus] = useState<IOption>(defaultFilters.status);
  const [selectedUser, setSelectedUser] = useState<string | undefined>();
  const [selectedSubmitter, setSelectedSubmitter] = useState<string | undefined>();

  const [rowSearch, setRowSearch] = useState(defaultFilters.search);
  const { user } = useAuth();
  const { fetchAllAssignedTo, allAssignedTo } = usePipelineOutput();
  const { fetchAllSubmitters, allSubmitters } = usePipelineOutput();

  // load all the dropdown options just once
  useEffect(() => {
    if (!allAssignedTo.data) {
      fetchAllAssignedTo();
    }
    if (!allSubmitters.data) {
      fetchAllSubmitters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!user) return;

    if (allAssignedTo.data) {
      if (getFiltersFromStorage().selectedUser !== '') {
        setSelectedUser(getFiltersFromStorage().selectedUser);
        return;
      }

      const users = allAssignedTo.data.map((user: any) => user.name);
      if (users.includes(user.name)) {
        setSelectedUser(user.name);
      }
    }
  }, [user, allAssignedTo.data]);

  const [openedRows, setOpenedRows] = useState<string[]>([]);

  const { pipelineOutputResults, fetchPipelineOutputResults } = usePipelineOutput();

  // only run this initialization step when the screen first loads
  const fetchPipelineOutputData = useCallback(
    (search?: string) => {
      try {
        fetchPipelineOutputResults({
          status: selectedStatus.value,
          affiliateId: selectedAffiliate.value,
          search,
          sortCol,
          sortOrder: sortOrder as IPipelineOutputRequestSortOrder,
          assignedToName: selectedUser,
          submitterName: selectedSubmitter,
        });
      } catch (err) {
        console.error(err);
      }
    },
    [
      fetchPipelineOutputResults,
      selectedStatus,
      selectedAffiliate,
      selectedSubmitter,
      sortCol,
      sortOrder,
      selectedUser,
    ],
  );

  useEffect(() => {
    const getData = setTimeout(() => {
      fetchPipelineOutputData(rowSearch);
    }, 500);

    return () => clearTimeout(getData);
  }, [rowSearch, sortCol, sortOrder, selectedAffiliate, selectedSubmitter, selectedStatus, selectedUser]);

  const dashboardRows = useCallback(
    (pipelineOutputsData: IPipelineOutput[]): DashboardRow[] => {
      return pipelineOutputsData.map(po => ({
        id: po.recordId,
        date: formatSourceDate(po.entryDate),
        status: po.formStatus,
        result: capitalizeFirstLetter(po?.modelDecision),
        confidence: capitalizeFirstLetter(po?.decisionConfidence),
        summary: po.transcriptSummary.toString(),
        selected: false,
        typeName: po.typeName,
        submitterName: po.submitterName,
        sku: po.sku !== null ? po.sku : 'None',
        speakerFullName: po.speakerFullName !== null ? po.speakerFullName.split('<br/>') : ['None'],
        assignedTo: po.assignedToName,
        englishTitle: po.titleEnglish,
        applyCode: po.applyCode,
        open: openedRows.includes(po.recordId),
        modelDecision: capitalizeFirstLetter(po?.modelDecision),
        decisionConfidence: capitalizeFirstLetter(po?.decisionConfidence),
        analystDecision: po.analystDecision,
      }));
    },
    [openedRows],
  );

  function handleBtnClick(row: DashboardRow) {
    if (row.open === true) {
      setOpenedRows(openedRows.filter(id => id !== row.id));
    } else {
      setOpenedRows([...openedRows, row.id]);
    }
  }

  function sortBy(col: string) {
    if (sortCol === col) {
      setSortOrder(sortOrder === 'DESC' ? 'ASC' : 'DESC');
    } else {
      setSortCol(col);
      setSortOrder('DESC');
    }
  }

  return (
    <Container className="approval">
      <Card>
        <Card.Body>
          <div className="color-scheme--acdp-na">
            <Row>
              <Col>
                <div className="title">
                  <Typography variant="heading">Compliance Companion</Typography>
                </div>
                <br />
              </Col>
            </Row>
            <Row>
              <DashboardFilters
                rowSearch={rowSearch}
                setRowSearch={setRowSearch}
                selectedAffiliate={selectedAffiliate}
                selectedStatus={selectedStatus}
                selectedUser={selectedUser}
                selectedSubmitter={selectedSubmitter}
                setSelectedAffiliate={setSelectedAffiliate}
                setSelectedStatus={setSelectedStatus}
                setSelectedUser={setSelectedUser}
                setSelectedSubmitter={setSelectedSubmitter}
              />
            </Row>
            <div className="flex">
              <div className="table">
                <TableContainer component={Paper}>
                  <Table aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <>
                          {tableCols.map((col: any) => (
                            <TableCell
                              key={'col' + col.sort}
                              id={'col' + col.sort}
                              style={columnTitleStyle}
                              width={col.width || null}>
                              <span
                                onClick={() => {
                                  sortBy(col.sort.toString());
                                }}>
                                {col.label}
                                {sortCol === col.sort && sortOrder === 'DESC' && <BsCaretDownFill />}
                                {sortCol === col.sort && sortOrder === 'ASC' && <BsCaretUpFill />}
                              </span>
                            </TableCell>
                          ))}
                          <TableCell width={20} align="right" style={columnTitleStyle}>
                            &nbsp;
                          </TableCell>
                        </>
                      </TableRow>
                    </TableHead>
                    <ExternalDataRenderer
                      externalData={pipelineOutputResults}
                      makeLoadingElement={() => <TableLoadingComponent colsNumber={tableCols.length} />}
                      makeDataElement={data => (
                        <TableBody>
                          {dashboardRows(data).map((row: DashboardRow) => (
                            <>
                              <TableRow
                                id={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                className={`tableRow ${row.selected === true ? 'selectedRow' : null}`}>
                                <TableCell>
                                  <a href={'/submission?id=' + row.id}>{row.applyCode}</a>
                                </TableCell>
                                <TableCell>{row.date}</TableCell>
                                <TableCell>{row.assignedTo}</TableCell>
                                <TableCell>{row.typeName}</TableCell>
                                <TableCell>{row.englishTitle}</TableCell>
                                <TableCell>{row.submitterName}</TableCell>
                                <TableCell>{row.sku}</TableCell>
                                <TableCell>
                                  {row.speakerFullName.map((name, index) => (
                                    <p className="speaker-full-name" key={index}>
                                      {name}
                                    </p>
                                  ))}
                                </TableCell>
                                <TableCell>{row.status}</TableCell>
                                <TableCell>{row.modelDecision}</TableCell>
                                <TableCell>{row.decisionConfidence}</TableCell>
                                <TableCell>
                                  <IconButton
                                    IconComponent={row.open ? BsChevronUp : BsChevronDown}
                                    color="#000"
                                    size="14px"
                                    className="table-icon"
                                    onClick={() => handleBtnClick(row)}
                                  />
                                </TableCell>
                              </TableRow>
                              <TableRow
                                id={'sub-' + row.id}
                                className={`tableSubRow ${row.open === true ? 'open' : ''}`}>
                                <TableCell colSpan={11}>{row.summary}</TableCell>
                              </TableRow>
                            </>
                          ))}
                        </TableBody>
                      )}
                    />
                  </Table>
                </TableContainer>
              </div>
              {summary}
            </div>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
}
