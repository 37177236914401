import axios from 'axios';
import { WithAbortFn } from '../../@types/external-api';
import { apiWrapper } from '../../config/api-wrapper';
import { ViolationRule } from './violation-rule-types';

class ViolationRuleService {
  public getViolationRules(): WithAbortFn<Promise<ViolationRule[]>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api.get('/classification-code').then((res: any) => res.data),
      abort: source.cancel,
    };
  }
}

const violationRuleService = new ViolationRuleService();
export default violationRuleService;
