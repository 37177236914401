import { IAffiliate, IAssignedTo, IStatus } from './pipeline-output-types';

export const affiliateMapper = (apiData: any): IAffiliate[] => {
  return apiData.map((data: any) => ({
    id: data?.[0] ?? '',
    affiliateId: data?.[1] ?? '',
    affiliateName: data?.[2] ?? '',
  }));
};

export const statusMapper = (apiData: any): IStatus[] => {
  return apiData.map((data: any) => ({
    name: data?.[0] ?? '',
  }));
};

export const assignedToMapper = (apiData: any): IAssignedTo[] => {
  const result = apiData
    .map((data: any) => ({
      id: data?.[0] ?? '',
      name: `${data?.[1] ?? ''} ${data?.[2] ?? ''}`,
      userId: data?.[3] ?? '',
    }))
    .filter(
      (obj: IAssignedTo, index: number, array: IAssignedTo[]) =>
        !array.slice(0, index).some(otherObj => otherObj.name === obj.name),
    );

  return result;
};
