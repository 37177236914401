import React from 'react';
import { FiAlertTriangle, FiInfo } from 'react-icons/fi';

import './index.scss';

const alertTypes = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'];

export default function AlertComponent(props: {
  text: string;
  type?: string;
  onClick?: (event: any) => void;
  buttonText?: string;
}) {
  const type = alertTypes.includes(props.type || '') ? props.type : 'primary';

  return (
    <div className={`review-rv-alert-container ${type}`}>
      <div className="review-rv-alert-content">
        {type === 'danger' ? <FiAlertTriangle /> : <></>}
        {type === 'info' ? <FiInfo /> : <></>}
        <p className="review-rv-alert-text">{props.text}</p>
      </div>
      {props.buttonText ? (
        <span className="review-rv-alert-button" onClick={props.onClick}>
          {props.buttonText}
        </span>
      ) : null}
    </div>
  );
}
