import { Review } from '../interface/review';
import moment from 'moment';

export function getLastReview(reviews: Review[]): Review {
  const lastIndex = reviews.length - 1;
  const lastReview = reviews.at(lastIndex) as Review;

  return lastReview;
}

export function formatDate(date: Date, format?: string) {
  const defaultFormat = 'MMMM Do YYYY, h:mm a';

  return moment(date).format(format ? format : defaultFormat);
}

export function hasTheReviewBeenSent(review: Review) {
  if (!review) {
    return false;
  }

  return review.status === 'SENT';
}
