export enum Permission {
  ManageOwnPermissions = 'ManageOwnPermissions',
  BasicAccess = 'BasicAccess',
  TestingTool = 'TestingTool',
  Tester = 'Tester',
}

export type FeaturesMap = Record<string, string[]>;

export interface Authorization {
  permissions: Permission[];
  features: string[];
}

export interface ClaimPermissions {
  name: string;
  permissions: Permission[];
}
