import axios from 'axios';
import { WithAbortFn } from '../../@types/external-api';
import { apiWrapper } from '../../config/api-wrapper';
import { IssueHightlight, ReviewIssue, ReviewIssueViolationRule, TranscriptVersion } from './submission-types';
import { SubmissionIssueDTO, UpdateUserReviewStatusResponseDTO } from '../pipeline-output/pipeline-output-types';

class SubmissionService {
  public createSubmissionIssue(
    reviewIssue: ReviewIssue,
    recordId: string,
    reviewIssueViolationRule: ReviewIssueViolationRule[],
  ): WithAbortFn<Promise<SubmissionIssueDTO>> {
    const source = axios.CancelToken.source();
    const updatedReviewIssue = { ...reviewIssue, issues: JSON.stringify(reviewIssue.issues) };
    const data = { reviewIssue: updatedReviewIssue, reviewIssueViolationRule };

    return {
      promise: apiWrapper.api
        .put(`/submission/submission-issue/create?recordId=${recordId}`, data)
        .then((res: any) => res.data)
        .then((submissionIssue: SubmissionIssueDTO) => {
          return {
            ...submissionIssue,
            reviewIssue: {
              ...submissionIssue.reviewIssue,
              issues: JSON.parse(submissionIssue.reviewIssue.issues.toString()) as IssueHightlight[],
            },
          } as SubmissionIssueDTO;
        }),
      abort: source.cancel,
    };
  }

  public updateSubmissionIssues(
    recordId: string,
    submissionReviews: SubmissionIssueDTO[],
  ): WithAbortFn<Promise<SubmissionIssueDTO[]>> {
    const source = axios.CancelToken.source();

    const updatedSubmissionReviews = submissionReviews.map(submissionReview => {
      return {
        ...submissionReview,
        issues: JSON.stringify(submissionReview.reviewIssue.issues),
        reviewIssue: {
          ...submissionReview.reviewIssue,
          issues: JSON.stringify(submissionReview.reviewIssue.issues),
        },
      };
    });

    return {
      promise: apiWrapper.api
        .put(`/submission/submission-issues/update?recordId=${recordId}`, updatedSubmissionReviews)
        .then((res: any) => res.data)
        .then((submissionIssues: SubmissionIssueDTO[]) => {
          return submissionIssues;
        }),
      abort: source.cancel,
    };
  }

  public deleteSubmissionIssues(submissionReviews: SubmissionIssueDTO[]): WithAbortFn<Promise<SubmissionIssueDTO[]>> {
    const source = axios.CancelToken.source();

    const removedSubmissionReviews = submissionReviews.map(submissionReview => {
      return {
        ...submissionReview,
        issues: JSON.stringify(submissionReview.reviewIssue.issues),
        reviewIssue: {
          ...submissionReview.reviewIssue,
          issues: JSON.stringify(submissionReview.reviewIssue.issues),
        },
      };
    });

    return {
      promise: apiWrapper.api.put(`/submission/submission-issues/delete`, removedSubmissionReviews).then((res: any) => {
        return res;
      }),
      abort: source.cancel,
    };
  }

  public updateSubmissionIssue(
    reviewIssue: ReviewIssue,
    recordId: string,
    reviewIssueViolationRule: ReviewIssueViolationRule[],
  ): WithAbortFn<Promise<SubmissionIssueDTO>> {
    const source = axios.CancelToken.source();
    const updatedReviewIssue = { ...reviewIssue, issues: JSON.stringify(reviewIssue.issues) };
    const data = { reviewIssue: updatedReviewIssue, reviewIssueViolationRule };

    return {
      promise: apiWrapper.api
        .put(`/submission/submission-issue/update?recordId=${recordId}`, data)
        .then((res: any) => res.data)
        .then((submissionIssue: SubmissionIssueDTO) => {
          return {
            ...submissionIssue,
            reviewIssue: {
              ...submissionIssue.reviewIssue,
              issues: JSON.parse(submissionIssue.reviewIssue.issues.toString()) as IssueHightlight[],
            },
          } as SubmissionIssueDTO;
        }),
      abort: source.cancel,
    };
  }

  public updateUserReviewStatus(reviewIssueViolationRuleId: number, userReviewStatus: boolean) {
    const source = axios.CancelToken.source();
    const data = { userReviewStatus };

    return {
      promise: apiWrapper.api
        .put(`/submission/submission-issue/user-review?reviewIssueViolationRuleId=${reviewIssueViolationRuleId}`, data)
        .then((res: any) => res.data)
        .then(
          (updateUserReviewStatusResponseDTO: UpdateUserReviewStatusResponseDTO) => updateUserReviewStatusResponseDTO,
        ),
      abort: source.cancel,
    };
  }

  public createTranscriptVersion(submissionId: number, data: TranscriptVersion) {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.post(`/features/transcript/${submissionId}`, data).then((res: any) => res.data),
      abort: source.cancel,
    };
  }

  public getTranscriptVersion(submissionId: number, versionNumber: number) {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.get(`/features/versions/${submissionId}/${versionNumber}`).then((res: any) => res.data),
      abort: source.cancel,
    };
  }
}

const submissionService = new SubmissionService();
export default submissionService;
