import React from 'react';

function RuleViolationIcon({ fill }) {
  return (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.99972 8.99353C6.60833 8.99353 6.28024 9.32161 6.28024 9.71301C6.28024 10.1044 6.60833 10.4325 6.99972 10.4325C7.37673 10.4325 7.7192 10.1044 7.70193 9.73028C7.7192 9.31873 7.394 8.99353 6.99972 8.99353Z"
        fill={fill}
      />
      <path
        d="M13.6593 11.5433C14.1112 10.7634 14.114 9.83384 13.6651 9.0568L9.15826 1.25189C8.71218 0.466222 7.90637 0 7.0027 0C6.09904 0 5.29322 0.4691 4.84714 1.24902L0.334571 9.06256C-0.114383 9.84823 -0.111505 10.7835 0.343205 11.5635C0.79216 12.3347 1.5951 12.7981 2.49301 12.7981H11.4951C12.3959 12.7981 13.2046 12.329 13.6593 11.5433ZM12.6808 10.9792C12.4304 11.4109 11.9873 11.6671 11.4922 11.6671H2.49013C2.00088 11.6671 1.56056 11.4167 1.31594 10.9936C1.06844 10.5648 1.06556 10.0526 1.31306 9.62087L5.82563 1.81021C6.07026 1.3814 6.5077 1.12814 7.0027 1.12814C7.49482 1.12814 7.93515 1.38428 8.17977 1.81309L12.6895 9.62375C12.9312 10.0439 12.9283 10.5504 12.6808 10.9792Z"
        fill={fill}
      />
      <path
        d="M6.82143 3.94271C6.47896 4.04055 6.26599 4.35137 6.26599 4.72838C6.28326 4.95573 6.29765 5.18597 6.31492 5.41332C6.36384 6.27957 6.41276 7.12856 6.46169 7.99481C6.47896 8.28836 6.70631 8.50132 6.99986 8.50132C7.29341 8.50132 7.52364 8.27397 7.53803 7.97754C7.53803 7.79911 7.53803 7.63507 7.5553 7.45376C7.58695 6.89832 7.62149 6.34289 7.65315 5.78745C7.67041 5.42771 7.70207 5.06797 7.71934 4.70823C7.71934 4.57872 7.70207 4.46361 7.65315 4.34849C7.50637 4.02617 7.1639 3.86212 6.82143 3.94271Z"
        fill={fill}
      />
    </svg>
  );
}

export default RuleViolationIcon;
