import { InputLabel } from '@mui/material';
import { Row } from 'react-bootstrap';
import MultiSelectDropdowComponent, { IOption } from '../../../components/ui/multi-select-dropdown';
import AsyncAutocompleteComponent from '../../../components/ui/async-autocomplete';
import { useCallback, useEffect, useMemo } from 'react';
import { Item } from '../../../components/ui/dropdown-btn';
import { getFiltersFromStorage, saveFiltersToStorage } from '../../../helpers/filters-local-storage';
import usePipelineOutput from '../../../resources/pipeline-output/pipeline-output-hook';

interface Props {
  rowSearch: string;
  setRowSearch: (value: string) => void;
  selectedAffiliate: IOption;
  setSelectedAffiliate: (value: IOption) => void;
  selectedStatus: IOption;
  setSelectedStatus: (value: IOption) => void;
  selectedSubmitter: string | undefined;
  setSelectedSubmitter: (value: string | undefined) => void;
  selectedUser: string | undefined;
  setSelectedUser: (value: string | undefined) => void;
}

const DashboardFilters: React.FC<Props> = ({
  rowSearch,
  setRowSearch,
  selectedAffiliate,
  setSelectedAffiliate,
  selectedStatus,
  setSelectedStatus,
  selectedSubmitter,
  setSelectedSubmitter,
  selectedUser,
  setSelectedUser,
}) => {
  const { fetchAllAffiliates, fetchAllStatus, allAffiliates, allSubmitters, allStatus, allAssignedTo } =
    usePipelineOutput();

  // load all the dropdown options just once
  useEffect(() => {
    if (!allAffiliates.data) {
      fetchAllAffiliates();
    }
    if (!allStatus.data) {
      fetchAllStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allAffiliatesOptions = useMemo(() => {
    let options: IOption[] = [{ value: '', label: '' }];
    if (!allAffiliates.data) return options;
    options = options.concat(allAffiliates.data.map(aff => ({ value: aff.id, label: aff.affiliateName })));
    return options.sort((a, b) => (a.label > b.label ? 1 : -1));
  }, [allAffiliates.data]);

  const allStatusOptions = useMemo(() => {
    let options: IOption[] = [{ value: '', label: '' }];
    if (!allStatus.data) return options;
    options = options.concat(allStatus.data.map(status => ({ value: status.name, label: status.name })));
    return options.sort((a, b) => (a.label > b.label ? 1 : -1));
  }, [allStatus.data]);

  const allSubmittersOptions = useMemo<Item[]>(() => {
    if (!allSubmitters.data) return [] as Item[];
    const submitter = allSubmitters.data.map(submitter => ({
      id: submitter.name,
      label: submitter.name,
      thirdLabel: '',
    })) as Item[];
    return submitter;
  }, [allSubmitters.data]);

  const allUsersOptions = useMemo<Item[]>(() => {
    if (!allAssignedTo.data) return [] as Item[];
    const assignedTo = allAssignedTo.data.map(user => ({
      id: user.name,
      label: user.name,
      thirdLabel: user.userId,
    })) as Item[];
    return assignedTo;
  }, [allAssignedTo.data]);

  const handleAffiliateChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedAffiliate = event.target.value;
      const selectedOption = allAffiliatesOptions.find(option => option.value === selectedAffiliate);
      setSelectedAffiliate(selectedOption!);
      saveFiltersToStorage({ ...getFiltersFromStorage(), affiliate: selectedOption! });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allAffiliates.data],
  );

  const handleStatusChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedStatus = event.target.value;
      const selectedOption = allStatusOptions.find(option => option.value === selectedStatus);
      setSelectedStatus(selectedOption!);
      saveFiltersToStorage({ ...getFiltersFromStorage(), status: selectedOption! });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allStatus.data],
  );

  const handleSubmitterChange = useCallback(
    (selectedSubmitter: string) => {
      setSelectedSubmitter(selectedSubmitter);
      saveFiltersToStorage({ ...getFiltersFromStorage(), selectedSubmitter });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allSubmitters.data],
  );

  const handleSelectedUserChange = useCallback(
    (selectedUser: string) => {
      setSelectedUser(selectedUser);
      saveFiltersToStorage({ ...getFiltersFromStorage(), selectedUser });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allAssignedTo.data],
  );

  const selectedUserItem = useMemo(
    () => (selectedUser ? allUsersOptions.find(it => it.id === selectedUser) ?? null : null),
    [selectedUser, allUsersOptions],
  );

  const selectedSubmitterItem = useMemo(
    () => (selectedSubmitter ? allSubmittersOptions.find(it => it.id === selectedSubmitter) ?? null : null),
    [selectedSubmitter, allSubmittersOptions],
  );

  const handleUserSearch = useCallback(
    (usernameOrEmail: string) => {
      return Promise.resolve(
        usernameOrEmail !== ''
          ? allUsersOptions.filter(
              userOption =>
                userOption.label.toLowerCase().startsWith(usernameOrEmail.toLowerCase()) ||
                userOption.thirdLabel?.toLowerCase()?.startsWith(usernameOrEmail.toLowerCase()),
            )
          : allUsersOptions,
      );
    },
    [allUsersOptions],
  );

  const handleSubmitterSearch = useCallback(
    (submitter: string) => {
      return Promise.resolve(
        submitter !== ''
          ? allSubmittersOptions.filter(submitterOption =>
              submitterOption.label.toLowerCase().startsWith(submitter.toLowerCase()),
            )
          : allSubmittersOptions,
      );
    },
    [allSubmittersOptions],
  );

  return (
    <div className="flex options">
      <Row className="id-search">
        <InputLabel className="input-label-search">
          <b>Search:</b>
        </InputLabel>
        <input
          value={rowSearch}
          placeholder="Enter a full or partial Apply Code or any text"
          onChange={event => {
            setRowSearch(event.target.value);
            saveFiltersToStorage({ ...getFiltersFromStorage(), search: event.target.value });
          }}
        />
      </Row>

      <Row className="id-filter">
        <InputLabel className="input-label">
          <b>Affiliate:</b>
        </InputLabel>
        <MultiSelectDropdowComponent
          options={allAffiliatesOptions}
          onChange={handleAffiliateChange}
          value={selectedAffiliate}
        />
      </Row>

      <Row className="id-filter">
        <InputLabel className="input-label">
          <b>Status:</b>
        </InputLabel>
        <MultiSelectDropdowComponent options={allStatusOptions} onChange={handleStatusChange} value={selectedStatus} />
      </Row>

      <Row className="id-filter">
        <AsyncAutocompleteComponent
          id="user-async-autocomplete"
          label="Submitted By:"
          placeholder="Search submitter"
          styleType="filter"
          items={allSubmittersOptions}
          search={handleSubmitterSearch}
          selectedValue={selectedSubmitterItem}
          selectValue={item => handleSubmitterChange(item?.label as string)}
        />
      </Row>

      <Row className="id-filter">
        <AsyncAutocompleteComponent
          id="user-async-autocomplete"
          label="Assigned To:"
          placeholder="Search User ID or email"
          styleType="filter"
          items={allUsersOptions}
          search={handleUserSearch}
          selectedValue={selectedUserItem}
          selectValue={item => handleSelectedUserChange(item?.label as string)}
        />
      </Row>
    </div>
  );
};

export default DashboardFilters;
