import { useCallback, useState } from 'react';
import ViolationRuleContext from './violation-rule-context';
import { ViolationRule } from './violation-rule-types';
import { ExternalData } from '../../@types/external-api';
import {
  makeExternalCallErrorData,
  makeExternalDataInitialData,
  makeExternalDataSuccessData,
} from '../../helpers/external-data';
import violationRuleService from './violation-rule.service';

interface ViolationRuleProviderProps {
  children: React.ReactNode;
}

const ViolationRuleProvider: React.FC<ViolationRuleProviderProps> = ({ children }) => {
  const [violationRules, setViolationRules] = useState<ExternalData<ViolationRule[]>>(makeExternalDataInitialData());

  const fetchViolationRules = useCallback(() => {
    setViolationRules(makeExternalDataInitialData());
    try {
      const { promise, abort } = violationRuleService.getViolationRules();
      promise.then(data => setViolationRules(makeExternalDataSuccessData(data)));
      return abort;
    } catch (err: any) {
      setViolationRules(makeExternalCallErrorData(err));
    }
  }, []);

  return (
    <ViolationRuleContext.Provider
      value={{
        violationRules,
        fetchViolationRules,
      }}>
      {children}
    </ViolationRuleContext.Provider>
  );
};

export default ViolationRuleProvider;
