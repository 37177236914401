import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit';
import { IssueHightlight, ReviewIssueViolationRule } from '../../resources/submission/submission-types';

interface RuleViolationModalState {
  showModal: boolean;
  mode: string;
  currentHighlightsArray: IssueHightlight[];
  originalHighlightsArray: IssueHightlight[];
  currentContext: string;
  originalContext: string;
  currentIssueContent: string;
  reviewIssueId: number;
  reviewIssueViolationRules: ReviewIssueViolationRule[];
  timeStart: number;
  timeEnd: number;
  suggestedContext: string;
}

const initialState: RuleViolationModalState = {
  showModal: false,
  mode: '',
  currentHighlightsArray: [],
  originalHighlightsArray: [],
  currentContext: '',
  originalContext: '',
  currentIssueContent: '',
  reviewIssueId: -1,
  reviewIssueViolationRules: [],
  timeStart: -1,
  timeEnd: -1,
  suggestedContext: '',
};

const ruleViolationModalSlice: Slice = createSlice({
  name: 'ruleViolationModal',
  initialState: initialState,
  reducers: {
    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
    setMode: (state, action: PayloadAction<string>) => {
      state.mode = action.payload;
    },
    setCurrentHighlightsArray: (state, action: PayloadAction<IssueHightlight[]>) => {
      const highlightsArray = [...action.payload];
      state.currentHighlightsArray = highlightsArray.filter(highlight => highlight.issueContent != '');
    },
    setOriginalHighlightsArray: (state, action: PayloadAction<IssueHightlight[]>) => {
      state.originalHighlightsArray = action.payload;
    },
    setCurrentContext: (state, action: PayloadAction<string>) => {
      state.currentContext = action.payload;
    },
    setOriginalContext: (state, action: PayloadAction<string>) => {
      state.originalContext = action.payload;
    },
    setCurrentIssueContent: (state, action: PayloadAction<string>) => {
      state.currentIssueContent = action.payload;
    },
    setReviewIssueId: (state, action: PayloadAction<number>) => {
      state.reviewIssueId = action.payload;
    },
    setReviewIssueViolationRules: (state, action: PayloadAction<ReviewIssueViolationRule[]>) => {
      state.reviewIssueViolationRules = action.payload;
    },
    setTimeStart: (state, action: PayloadAction<number>) => {
      state.timeStart = action.payload;
    },
    setTimeEnd: (state, action: PayloadAction<number>) => {
      state.timeEnd = action.payload;
    },
    setSuggestedContext: (state, action: PayloadAction<string>) => {
      state.suggestedContext = action.payload;
    },
    clearModalState: (state, _: PayloadAction) => {
      state.showModal = false;
      state.mode = '';
      state.currentHighlightsArray = [];
      state.originalHighlightsArray = [];
      state.currentContext = '';
      state.originalContext = '';
      state.currentIssueContent = '';
      state.reviewIssueId = -1;
      state.reviewIssueViolationRules = [];
      state.timeStart = -1;
      state.timeEnd = -1;
    },
  },
});

export const {
  setShowModal,
  setMode,
  setCurrentHighlightsArray,
  setOriginalHighlightsArray,
  setCurrentContext,
  setOriginalContext,
  setCurrentIssueContent,
  setReviewIssueId,
  setReviewIssueViolationRules,
  setTimeStart,
  setTimeEnd,
  clearModalState,
  setSuggestedContext,
} = ruleViolationModalSlice.actions;

export default ruleViolationModalSlice.reducer;
