import { createContext } from 'react';
import { SingInOptions } from './auth-types';
import { User } from './user-model';
import { ExternalData } from '../../@types/external-api';

export interface AuthContextType {
  user?: User;
  isAuthenticated: boolean | null;
  signIn: (option: SingInOptions) => void;
  signOut: () => void;
  fetchAllUsers: () => Promise<void>;
  allUsers: ExternalData<User[]>;
}

const AuthContext = createContext<AuthContextType>(null!);

export default AuthContext;
